import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './topicIndexHome.css';

class TopicIndexHome extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            theme:[],
            alltheme: this.props.alltheme?this.props.alltheme:[] ,
            startIdx: 0
        };
    }
    componentDidMount(){
        if(this.state.alltheme){
            this.refreshTheme()
        }
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.alltheme
        console.log("topic newdata:", newdata)
        this.setState({
            alltheme: newdata,
        },()=>{
            this.refreshTheme()
        })
    }
    refreshTheme =()=> {
        var alltheme = this.state.alltheme
        var startIdx = this.state.startIdx
        if (startIdx === alltheme.length) {
            startIdx = 0
        }
        var theme = []
        for (var i = startIdx; i < startIdx+6 && i<alltheme.length;i++) {
            theme.push(alltheme[i])
        }
        this.setState({
            theme: theme,
            startIdx: Math.min(startIdx+6, alltheme.length)
        })
    }
    render() {
        console.log("theme list:", this.state.theme);
        return (
            <div className=" flex-column flex-center">
                <div className="sub-title">专题推荐</div>
                <div className="sub-navbar">ROCOMMEND TOPICS</div>
                <div className="change-btn" style={{ cursor: "pointer" }} onClick={this.refreshTheme}>换一批</div>
                <div className="list-wrapper flex-row flex-wrap">
                {
                    this.state.theme.map((theme,key)=>{
                        let path={
                            pathname: '/TusstarWeb/topic-detail',
                            state: theme
                        }
                        return (
                            <div className="list-item" key={key}>
                                <Link className="link-style-clear" to={path}>
                                    <img src={theme.image} alt="img"/>
                                </Link>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        );
    }
}

export default TopicIndexHome;