import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Text from '../Text'
import '../../global.css'
import './sponsorItem.css'
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {unLikeSponsor} from '../../data'

class SponsorItem extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            data: this.props.data,
            type: this.props.type, // 活动 or 主办方
            showRank: this.props.showRank,
            idx:parseInt(this.props.idx?this.props.idx:0)
        }
    }
    changeLikeSponsor= (sponsorId)=>{
        confirmAlert({
            title: '取消关注',
            message: '你确定取消关注吗？',
            buttons: [
              {
                label: '确定取消',
                onClick: () => {
                    console.log("确定取消");
                    unLikeSponsor(sponsorId).then(res=>{
                        if(res.data.message == "SUCCESS"){
                            this.props.changeLikeSponsor(sponsorId)
                        }
                    })
                }
              },
              {
                label: '容我想想',
                onClick: () => {
                    console.log("取消取消");
                }
              }
            ]
        });
    }
    render() {
        let data = this.state.data;
        let type = this.state.type;
        let status = data.status ? data.status: '';
        let actStatusBox;
        if (type==='sponsor'&& status ==='') {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div style={{ cursor: "pointer" }} onClick={this.changeLikeSponsor.bind(this, data.id)} className="act-signup">取消关注</div>
                </div>
            );
        } else if (type==='sponsor') {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-cost">免费</div>
                    <div style={{ cursor: "pointer" }} onClick={this.changeLikeSponsor(data.id)} className="act-signup">取消关注</div>
                </div>
            );
        }

        let path={
            pathname: "/TusstarWeb/sponsor",
            state: data.id
        }

        return (
                <div className="act-wrapper flex-row">
                <Link className="link-style-clear flex-row" to={path}>
                    <img src={data.image} alt="" className="act-img"/>
                    <div className="act-content flex-column">
                        <div className="act-title">{data.name}</div>
                        <div className="act-desc">历史活动{data.activitiesNumber}</div>
                        <div className="act-desc">可报名活动{data.canJoinActivitiesNumber}</div>
                        <div className="act-desc">粉丝{data.fansNumber}</div>
                    </div>
                </Link>
                    {actStatusBox}
                </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        changeLikeSponsor: (sponsorId)=>{
            console.log("dispatch change_like_sponsor:", sponsorId)
            dispatch({
                type:"change_like_sponsor",
                sponsorId: sponsorId
            })
        }
    }
}
export default connect(null, mapDispatchToProps)(SponsorItem);