import React, { Component } from 'react';
import Welcome from './welcome/Welcome';
import Topic from './topic/Topic';
import RecomIndex from '../recommend/RecomIndex';
import FindIndex from '../find/FindIndex';
import {notExpireAct} from '../../utils/tools'

import { connect } from 'react-redux';
import './home.css'
class Home extends Component {

    render() {
        return (
            <div>
                <Welcome />
                <div className="bg-img">
                    <div className="page-padding">
                        <Topic />
                        <RecomIndex activityInfoOri={notExpireAct(this.props.activity)}  
                        activityByLoc={notExpireAct(this.props.activityByLoc)} type={"home"}
                        navTitle='可报名活动推荐'/>
                        <FindIndex activityInfoOri={notExpireAct(this.props.activityByLoc)} 
                        typeList={this.props.typeList} navTitle='可报名活动'/>
                    </div>
                </div>
            </div>
        );
    }
}   

function mapStateToProps(state) {
    return {
        sponsor: state.sponsor,
        activity: state.activity,
        activityByLoc: state.activityByLoc,
        typeList: state.typeList
    }
}

export default connect(mapStateToProps, null)(Home);