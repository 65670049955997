import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './global.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import locaList from './model/location'

import { createStore } from 'redux';
import { Provider, connect } from 'react-redux';

//let sponsor = getSponsorListSync()
//这是action
const action = {
  type: 'get_sponsor'
}
const defaultState = {
    sponsor:[],
    activity:[],
    topic:[],
    user:[],
    test:'test',
    loginStatus: localStorage.getItem("phone")?true:false,
    typeList: []
}
//这是reducer
const reducer = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state))
    console.log("reducer!!!!:",newState);
    switch (action.type) {
        case 'get_sponsor':
            console.log("reducer get_sponsor")
            newState.sponsor = action.sponsor
            return newState
        case 'get_activity':
            console.log("reducer get_activity")
            newState.activity = action.activity;
            newState.activityByLoc = filterActByLoc(action.activity);
            return newState
        case 'get_topic':
            console.log("reducer get_topic", action.topic)
            newState.topic = action.topic
            return newState
        case 'get_user':
            console.log("reducer get_user:", action.user)
            newState.user = action.user
            return newState
        case 'change_like_sponsor':
            console.log("reducer change_like_sponsor")
            newState.sponsor = changeLikeSponsor(newState.sponsor, action.sponsorId)
            return newState
        case 'change_like_act':
            console.log("reducer change_like_act")
            newState.activity = changeLikeAct(newState.activity, action.actId)
            return newState
        case 'set_userInfo':
            console.log("reducer set_userInfo", action.userInfo)
            newState.userInfo = action.userInfo
            return newState
        case 'set_activity_byloc':
            console.log("reducer set_activity_byloc", action.locIndex)
            newState.activityByLoc = filterActByLoc(newState.activity, action.locIndex)
            return newState
        case 'get_ads':
            console.log("reducer get_ads", action.locIndex)
            newState.ads = action.ads
            return newState
        case 'set_login_status':
            console.log("reducer set_login_status", action.loginStatus)
            newState.loginStatus = action.loginStatus
            return newState
        case 'change_attend_status':
            console.log("reducer change_attend_status", action.data)
            newState.activity = changeAttendStatus(newState.activity, action.data.actId, action.data.status)
            return newState
        case 'add_view_act':
            console.log("reducer add_view_act", action.actId)
            newState.activity = addViewAct(newState.activity, action.actId)
            return newState
        case 'get_city':
            console.log("reducer get_city", action.city)
            newState.cityList = action.city
            return newState
        case 'get_type':
            console.log("reducer get_type", action.typeList)
            newState.typeList = action.typeList
            return newState
        default:
            return state;
    }
  
}
const changeLikeSponsor = (sponsorData, sponsorId) =>{
    console.log("changeLikeSponsor:", sponsorData, sponsorId)
    let newSponsorData = sponsorData.map(function(sponsor){
        if(sponsor.id === sponsorId){
            if(sponsor.like){
                sponsor.likeNumber -= 1
            }else{
                sponsor.likeNumber += 1
            }
            sponsor.like = !sponsor.like;
        }
        return sponsor
    })
    console.log("changeLikeSponsor:", sponsorData, sponsorId, newSponsorData)
    return newSponsorData;
}

const changeLikeAct = (activityData, actId) =>{
    let newActivityData = activityData.map(function(act){
        console.log("map:", act.totalInfo.id, actId)
        if(act.totalInfo.like){
            act.totalInfo.likeNumber -= 1
        }else{
            act.totalInfo.likeNumber += 1
        }
        if(act.totalInfo.id === actId){
            act.totalInfo.like = !act.totalInfo.like;
        }
        return act
    })
    return newActivityData;
}

const changeAttendStatus = (activityData, actId, status) =>{
    let newActivityData = activityData.map(function(act){
        console.log("map:", act.totalInfo.id, actId)
        if(status === 1){
            act.totalInfo.attendNumber += 1
        }
        if(act.totalInfo.id === actId){
            act.totalInfo.attend = status;
        }
        return act
    })
    return newActivityData;
}

const addViewAct = (activityData, actId) =>{
    let newActivityData = activityData.map(function(act){
        console.log("map:", act.totalInfo.id, actId)
        if(act.totalInfo.id === actId){
            act.totalInfo.views += 1;
        }
        return act
    })
    return newActivityData;
}

const filterActByLoc = (activity, locIndex)=>{
    if(!locIndex){
        locIndex = localStorage.getItem("locIndex")
    }
    if(!locIndex || parseInt(locIndex) === 0){
        return activity
    }
    let loc = locaList[locIndex]
    let res=  activity.filter(x =>  x.totalInfo.cityName.indexOf(loc)!=-1)
    return res;
}
//创建store
const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
