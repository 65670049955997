import React, { Component } from 'react';
import FindIndex from "./FindIndex"
import { connect } from 'react-redux';
import {notExpireAct} from '../../utils/tools'

class Find extends Component {
    
    render() {
        return (
            <div className="page-padding">
                <FindIndex activityInfoOri={this.props.activityByLoc}
                typeList={this.props.typeList}  navTitle='找活动'/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activityByLoc: state.activityByLoc,
        typeList: state.typeList
    }
}

export default connect(mapStateToProps, null)(Find);