import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './activityItem3.css';

/*
*横着排列的样式
 */
class ActivityItem3 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            
        };
    }
    
    render() {
        let renderInfo;
        let props = this.props;
        let path={
            pathname: `/TusstarWeb/activity-detail/?id=${props.activityData.totalInfo.id}`,
            state: props.activityData.totalInfo.id
        }
        if (props.showType === 'img-left'){
            renderInfo = (
                <Link className="link-style-clear" to={path}>
                    <div className="activity-item flex-row flex-twoside">
                        <ImgBox imgUrl = {props.activityData.totalInfo.activityPicture}/>
                        <DescBox data = {props.activityData}/>
                    </div>
                </Link>
            )
        }else{
            renderInfo = (
                <Link className="link-style-clear" to={path}>
                    <div className="activity-item flex-row flex-twoside">
                        <DescBox data = {props.activityData}/>
                        <ImgBox imgUrl = {props.activityData.totalInfo.activityPicture}/>
                    </div>
                </Link>
            )
        }
        return (
            <div className="activity-item-wrapper">
                {renderInfo}
            </div>
        );
    }
}
class ImgBox extends Component {   
    render() {
        return (
            <img src={this.props.imgUrl} alt="" className="imgbox-wapper"/>
        );
    }
}

class DescBox extends Component {
    render() {
        let data = this.props.data;
        return (
            <div className="desbox-wrapper">
                <div className="desc-title">{data.title}</div>
                <div className="desc-content">√ {data.totalInfo?data.totalInfo.place:''}</div>
                <div className="desc-content">√ {data.timeInfo}</div>
                <div className="desc-content">√ {data.sponsor}</div>
            </div>
        );
    }
}

export default ActivityItem3;