import React, { Component } from 'react';

import './subNavBar.css';

class SubNavBar extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            navIdx: parseInt(this.props.navIdx)
        }
    }
    onNavClick = (e)=>{
        let newIdx = parseInt(e.target.dataset.key)
        this.props.onNavClick(newIdx);
        this.setState({
            navIdx: newIdx,
        })
    }

    render() {
        return (
            <div className="subNavBar flex-column flex-center">
                <div className="sub-title">{this.props.title}</div>
                <div className="sub-navbar-wrapper" style={{ cursor: "pointer" }}>
                    {
                        this.props.navList ? 
                        this.props.navList.map((navbar, key)=>{
                            return (
                                <div key={key} data-key={key} onClick={this.onNavClick}
                                className={this.state.navIdx ===key?"sub-navbar sub-navbar-select":"sub-navbar"}>
                                    {navbar}
                                </div>
                            )
                        }) : ''
                    }
                </div>
            </div>
        );
    }
}

export default SubNavBar;