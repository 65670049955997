import React, { Component } from 'react';
import { checkNotNull, checkPhone } from '../../utils/tools'
import { sendVeriCode, register, getInterestTagList } from '../../data'
import SubNavBar from '../../components/subNavBar/SubNavBar';
import { Checkbox } from 'antd';
import './register.css'

const navTitle = "用户注册"
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: ['', '', '', ''],
            showAlert: false,
            userPassword: '',
            formAlert: [true, true, true, true],
            alertMsg: ['输入有误', '输入有误', '输入有误', '密码不一致'],
            viewTimer: false,
            second: 60,
            interestTagList: [],
            interestTagCheckList: [
                {
                    idx: 0,
                    name: '投资人',
                    value: '',
                    checked: false
                }, {
                    idx: 1,
                    name: '创业者',
                    value: '',
                    checked: false
                }
            ]
        }

    }
    componentDidMount() {
        getInterestTagList().then(res => {
            let interestTagList = res.data.data;
            let interestTagCheckList = [];
            interestTagList.forEach((item, index) => {
                interestTagCheckList.push({
                    idx: index,
                    name: item.name,
                    value: '',
                    checked: false
                })
            })
            this.setState({
                interestTagList: interestTagList,
                interestTagCheckList: interestTagCheckList
            })
        })
    }
    handleChangeForInterestTag = (e) => {
        let options = e.target.options
        let idx = options.idx
        let interestTagCheckList = this.state.interestTagCheckList
        interestTagCheckList[idx].checked = e.target.checked
        this.setState({
            interestTagCheckList: interestTagCheckList
        })
    }

    changeFrom(num, e) {
        let val = e.target.value;
        let newFromValue = this.state.formValue;
        newFromValue[num] = val;
        this.setState({
            formValue: newFromValue,
            showAlert: false
        })
    }
    handleClick(name, pwd) {
        let formValue = this.state.formValue
        let checkRes = this.checkForm(formValue)
        let interestTag = this.state.interestTagCheckList.filter(item => item.checked == true).map(item => item.name)
        if (checkRes) {
            console.log("可以注册");
            register(formValue[0], formValue[2], interestTag).then(res => {
                console.log(res)
                if (!res.data || res.data.code !== 200) {
                    alert("注册失败：" + (res.data.message ? res.data.message : ''))
                } else {
                    alert("注册成功, 请登录")
                    this.props.history.push('/')
                }
            })
        }
    }
    checkForm(formValue) {
        let interestTag = this.state.interestTagCheckList.filter(item => item.checked == true).map(item => item.name)
        console.log(interestTag)
        let formAlert = this.state.formAlert
        formAlert[0] = !checkPhone(formValue[0]);
        formAlert[1] = !(checkNotNull(formValue[1]) && formValue[1] === this.state.smsCode);
        formAlert[2] = !(checkNotNull(formValue[2]) && formValue[2].split('').length >= 8);
        formAlert[3] = !(formValue[2] === formValue[3]);
        console.log("formAlert", formAlert, formValue)
        if (formAlert.toString() !== "false,false,false,false") {
            this.setState({
                formAlert: formAlert,
                showAlert: true
            })
            return false
        }

        if (this.state.interestTagList.length != 0 && this.state.interestTagCheckList.filter(item => item.checked == true).length == 0) {
            alert("请选择兴趣标签！")
            return false;
        }

        return true
    }
    onGetVeriCode() {
        var viewTimer = this.state.viewTimer
        var phone = this.state.formValue[0]
        if (!checkPhone(phone)) {
            alert("请输入正确的手机号！")
            return
        }
        if (viewTimer === true) {
            alert("请稍后尝试")
            return
        } else {
            this.setState({
                viewTimer: true
            })
        }
        var smsContent = this.getSmsContent()
        console.log('注册页面phone,smsContent: ', phone, smsContent)
        sendVeriCode(phone).then(res => {
            console.log('验证码结果：', res.data)
            if (res.data.code === 200 && res.data.message === 'SUCCESS') {
                this.timer()
                var smsCode = res.data.data
                this.setState({
                    smsCode: smsCode,
                    second: 60,
                    viewTimer: true
                })
            } else {
                alert('验证码发送失败')
                this.setState({
                    second: 60,
                    viewTimer: false
                })
            }
        })
    }
    getSmsContent() {
        var randNo = ''
        for (var i = 0; i < 4; i++) {
            var curNo = Math.floor(Math.random() * 10);
            randNo += curNo.toString()
        }
        this.setState({
            smsCode: randNo
        })
        return '验证码' + randNo + ',请注意保管。若非本人操作请删除此短信。'
    }
    timer() {
        let promise = new Promise((resolve, reject) => {
            let setTimer = setInterval(() => {
                this.setState({
                    second: this.state.second - 1,
                })
                if (this.state.second <= 0) {
                    this.setState({
                        second: 60,
                        viewTimer: false
                    })
                    resolve(setTimer)
                }
            }, 1000)
        })
        promise.then((setTimer) => {
            clearInterval(setTimer)
        })
    }
    render() {
        let codeMsg = this.state.viewTimer ? '已发送(' + this.state.second + ')' : '发送验证码'
        const  isInterestTag  =  this.state.interestTagList.length != 0 ;
        return (
            <div className="register-box page-padding">
                <SubNavBar title={navTitle} />
                <form action="" className="form-horizontal">
                    <div className="form-group input-text">
                        <input type="text" className="form-control" name="uphone" id="uphone" ref="uphone" placeholder="请输入手机号"
                            onChange={this.changeFrom.bind(this, 0)} />
                        <Hint hidden={this.state.showAlert && this.state.formAlert[0]} alertMsg={this.state.alertMsg[0]} />
                    </div>
                    <div className="form-group input-text">
                        <input type="text" className="form-control" name="ucode" id="ucode" ref="ucode" placeholder="请输入验证码"
                            onChange={this.changeFrom.bind(this, 1)} />
                        <div className="verift-btn" onClick={this.onGetVeriCode.bind(this)}>{codeMsg}</div>
                        <Hint hidden={this.state.showAlert && this.state.formAlert[1]} alertMsg={this.state.alertMsg[1]} />
                    </div>
                    <div className="form-group input-text">
                        <input type="password" className="form-control" name="password" id="upwd" ref="upwd" placeholder="密码至少8位"
                            onChange={this.changeFrom.bind(this, 2)} />
                        <Hint hidden={this.state.showAlert && this.state.formAlert[2]} alertMsg={this.state.alertMsg[2]} />
                    </div>
                    <div className="form-group input-text">
                        <input type="password" className="form-control" name="password2" id="upwd2" ref="upwd2" placeholder="请确认密码"
                            onChange={this.changeFrom.bind(this, 3)} />
                        <Hint hidden={this.state.showAlert && this.state.formAlert[3]} alertMsg={this.state.alertMsg[3]} />
                    </div>

                    <div className="form-group interest-tag">
                        {isInterestTag ? <div className="tag-title">兴趣标签</div> : ""}
                        {
                            this.state.interestTagCheckList.map((item, key) => {
                                return (
                                    <div className="tag-cell">
                                        <Checkbox options={item} checked={item.checked} onChange={this.handleChangeForInterestTag}>{item.name}</Checkbox>
                                        <br />
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="form-group">
                        <button type="button" onClick={this.handleClick.bind(this, this.state.userName, this.state.userPassword)} className="btn btn-primary login-btn">注册</button>
                    </div>
                </form>
            </div>
        );
    }
}

class Hint extends Component {
    render() {
        return (
            <div className="form-alert" hidden={!this.props.hidden}>{this.props.alertMsg}</div>
        )
    }
}
export default Register;