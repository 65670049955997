import React, { Component } from 'react';
import './recomIndex.css';
import ActivityItem2 from '../../components/activityItem/ActivityItem2';
import ActivityItem3 from '../../components/activityItem/ActivityItem3';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import {sortByEndTime} from '../../utils/tools'


const navList = ['时间排序','热门指数']; //'本地活动',
class RecomIndex extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            navIdx: 0,
            activityInfoOri: this.props.activityInfoOri,  //筛选的数据
            activityInfo:[], //筛选后展示的数据,
            activityByLoc: this.props.activityByLoc,
            displayNum: this.props.type === 'home'? 3: 9 ,
            filterLoc: false,
            filterTime: false,
            filterHot: false
        }
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps
        this.setState(newdata, ()=>{
            this.filterByTime();
        })
    }
    componentDidMount(){
        console.log("this.state.activityInfoOri:", this.state.activityInfoOri)
        this.filterByTime();
    }
    onNavClick = (idx)=> {
        this.setState({
            navIdx:  parseInt(idx),
        })
        console.log("onNavClick", idx);
        switch (parseInt(idx)){
            case 2:
                this.filterByLocation();
                break;   
            case 0:
                this.filterByTime();
                break;   
            case 1:
                this.filterByHot();
                break;
            default:
        }
    }

    filterByLocation = ()=>{
        this.setState({
            activityInfo: this.state.activityByLoc?this.state.activityByLoc:[],//.slice(0,this.state.displayNum):[],
            filterLoc: true
        });
        /*let curLoc = localStorage.getItem('currentCity').substr(0, 2);
        var activityInfoOri = this.state.activityInfoOri;
        var activityInfoFilter = activityInfoOri.filter(x => x.totalInfo.cityName.indexOf(curLoc) != -1)
        this.setState({
            activityInfoFilter: activityInfoFilter,
            activityInfo: activityInfoFilter.slice(0,this.state.displayNum),
            filterLoc: true
        });*/
    }
    filterByTime = ()=> {
        var activityInfoOri = this.state.activityByLoc?this.state.activityByLoc:[]
        var filterTime = this.state.filterTime
        var activityInfoFilter = sortByEndTime(activityInfoOri, filterTime)
        let halfYearTimestamp = (new Date()).getTime()-182*24*60*60*1000;
        activityInfoFilter = activityInfoFilter.filter(x=> x.totalInfo.beginTime >halfYearTimestamp)
        this.setState({
            activityInfoFilter: activityInfoFilter,
            activityInfo: activityInfoFilter,//.slice(0,this.state.displayNum),
            filterTime: !this.state.filterTime
        })
    }
    filterByHot = ()=>{
        var activityInfoOri = this.state.activityInfoOri
        var filterHot = this.state.filterHot
        var activityInfoFilter
        if (filterHot) {
            activityInfoFilter = activityInfoOri.sort((a, b) => a.totalInfo.attendNumber - b.totalInfo.attendNumber)
        } else {
            activityInfoFilter = activityInfoOri.sort((a, b) => b.totalInfo.attendNumber - a.totalInfo.attendNumber)
        }
        this.setState({
            activityInfoFilter: activityInfoFilter,
            activityInfo: activityInfoFilter,//.slice(0,this.state.displayNum),
            filterHot: !this.state.filterHot
        })
    }

    render() {
        let activityItem;
        //console.log("activityInfo", this.state.activityInfo);
        if(this.props.type === "home") {
            activityItem = this.state.activityInfo.map((activity, key)=>{
                return (
                    <ActivityItem3 key={activity.title}
                    showType={key%2 === 0 ? 'img-left':'img-right'}
                    activityData={activity}/>
                )
            })
        }else{
            activityItem = (
                <div className="activity-item-2-wrapper flex-row flex-wrap flex-y-start">
                    {  
                        this.state.activityInfo.map((activity, key)=>{
                            //console.log("activity", activity);
                            let size = key%2 === 0 ? 'normal':'high'
                            console.log("###", size, key, key%2, activity.title)
                            return (
                                <ActivityItem2 history={this.props.history} key={activity.title} activity={activity} size={size}
                                idx={key}/>
                            )
                        })
                    }
                </div>
            )
        }
        return (
            <div className="activity-wrapper flex-column flex-center">
                <SubNavBar title={this.props.navTitle} navList={navList} navIdx="0" 
                onNavClick={this.onNavClick}/>
                {  
                  activityItem  
                }
            </div>
        );
    }
}

export default RecomIndex;

