import React, { Component } from 'react';
class Text extends Component {
    render() {
        var styles = {  
            display: 'inline',
        }
        //console.log(this.props.children, typeof(this.props.children)) ; 
        let textStr = this.props.children;
        if(typeof(this.props.children) === "object"){
            textStr = this.props.children.join("").replace(/ /g, "\u00a0")
        }else if(typeof(this.props.children) === "string"){
            textStr.replace(/ /g, "\u00a0")
        }
        return (
            <div style={styles}>
                {textStr}                
            </div>
        );
    }
}

export default Text;