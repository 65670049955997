import React, { Component } from 'react';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import Select from '../../components/select/Select';
import ActivityItem from '../../components/activityItem/ActivityItem'
import locaList from '../../model/location'

import './rankIndex.css'

const navTitle = '排行榜';
const options1 = locaList.map((item, key)=>{
    return { value: key, label: item }
})
const options2 = [
    { value: 0, label: '热度榜' },
    { value: 1, label: '浏览榜' }
];
class RankIndex extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            selectedOption1: 0,  //locatiom
            selectedOption2: 0,  //按什么排序
            filterInfo:[0,0],
            activityInfoOri: this.props.activityInfoOri?this.props.activityInfoOri:[] ,
            activityInfo:[], //筛选后展示的数据
        }
    }
    componentDidMount() {
        this.filterActFun();
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activityInfoOri
        this.setState({
            activityInfoOri: newdata?newdata:[],
        },()=>{
            this.filterActFun(this.state.navIdx);
        })
    }
    filterActFun = ()=> {
        var activityInfoOri = this.state.activityInfoOri
        var filterInfo = this.state.filterInfo
        console.log('###: ', filterInfo)
        // 过滤city
        var locationidx = filterInfo[0]
        var loc = options1[locationidx].label
        var activityInfo0  = activityInfoOri;
        if(locationidx){
            activityInfo0 = activityInfoOri.filter(x => {
                console.log('###: ', x.totalInfo.cityName)
                return x.totalInfo.cityName.indexOf(loc)!=-1
            })
        }
        // 排序
        var sortidx = filterInfo[1]
        var activityInfo1 = []
        console.log('###: ', activityInfo0)
        switch (sortidx) {
          case 1:
            activityInfo1 = activityInfo0.sort((a, b) => b.totalInfo.views - a.totalInfo.views)
            break
          case 0:
            activityInfo1 = activityInfo0.sort((a,b) => b.totalInfo.attendNumber - a.totalInfo.attendNumber)
            break
          default:
            break
        } 
        this.setState({
          activityInfo: activityInfo1
        })
    }
    handleChange1 = selectedOption1 => {
        let newfilterInfo = this.state.filterInfo
        newfilterInfo[0] = selectedOption1
        this.setState({ filterInfo: newfilterInfo },()=>{this.filterActFun()});
    };
    handleChange2 = selectedOption2 => {
        let newfilterInfo = this.state.filterInfo
        newfilterInfo[1] = selectedOption2
        this.setState({ filterInfo: newfilterInfo },()=>{this.filterActFun()});
    };

    render() {
        const filterInfo = this.state.filterInfo;
        return (
            <div className="rank-wrapper flex-column page-min-height">
                <SubNavBar title={navTitle}/>
                <div className="select-wrapper flex-row flex-center">
                    <Select className="select select-border" data-key="1" value={filterInfo[0]} 
                    onChange={this.handleChange1} options={options1} />
                    <div>|</div>
                    <Select className="select" data-key="2" value={filterInfo[1]} 
                    onChange={this.handleChange2} options={options2} />
                </div>
                <div className="activity-wrapper full-width">
                    {
                        this.state.activityInfo.map((activity, key)=>{
                            return ( 
                                <ActivityItem key={activity.title} showRank={true} 
                                data={activity} idx={key}/>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default RankIndex;