import React, { Component } from 'react';
import './topicDetail.css'
import Text from "../../components/Text"
import Msg from "../../components/Msg"
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class TopicDetail extends Component {
    constructor(props) {
        super(props);
        this.state={
            themeInfo: this.props.location.state,  //接收上个组件传来的值
            themeActList:[],
            activityInfo:this.props.activity,
            nowDate: new Date().getTime(),
        }
    }

    componentDidMount() {
        this._updateActList();
        this.getSponsorInfo();
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activity
        console.log("newdata:", newdata)
        this.setState({
            activityInfo: newdata
        },()=>{
            this._updateActList()
        })
    }
    _updateActList = ()=> {
        var activityInfo = this.state.activityInfo
        var themeInfo = this.state.themeInfo
        var sponsorInfo = this.getSponsorInfo(themeInfo)
        console.log('activityInfo: ', activityInfo)
        var actIdSet = new Set(themeInfo.activityList.map(x => x.id))
        var themeActList = activityInfo.filter(x => actIdSet.has(x.totalInfo.id))
        console.log('ID set: ', actIdSet,themeActList)
        this.setState({
            themeActList: themeActList,
            sponsorInfo: sponsorInfo
        })
    }
    getSponsorInfo = (themeInfo)=>{
        let sponsorInfo = {};
        if(themeInfo && this.props.sponsor){
            sponsorInfo = this.props.sponsor.find(x=>x.id === themeInfo.sponsorId)
        }
        return sponsorInfo
    }
    render() {
        let themeInfo = this.state.themeInfo
        return (
            <div className="page-padding full-width">
                <div className="part1  flex-row flex-twoside">
                    <div className="part1-left">
                        <img src={themeInfo.image} alt="" className="part1-left-main-pic"/>
                    </div>
                    <div className="part1-right flex-column">
                        <div className="part1-right-title">{themeInfo.title}</div>
                        <div className="block-wrapper">
                            {/*<div className="act-title">活动安排</div>*/}
                            <div className="content">
                                <div>{this.state.sponsorInfo?this.state.sponsorInfo.name:''}</div>
                            </div>
                        </div>
                        <div className="part1-right-number">
                            {themeInfo.viewCount}浏览  |
                            {themeInfo.detail.activityList.length}活动  |
                            {themeInfo.joinUserCount}报名  |
                        </div>
                    </div>
                </div>
                {
                    themeInfo.detail.textList.map((value, key)=>{
                        return (
                            <div key={key}>
                                <div className="act-title act-title-margin">{value.title}</div>
                                <div className="content">
                                    <Msg text={value.description} />
                                </div>
                            </div>
                        )
                    })
                }
                <div className="act-title act-title-margin">我要报名</div>
                <div className="signup-list">
                    {
                        this.state.themeActList.map((item, key)=>{
                            let cannotSignup = item.totalInfo.canJoinTime <= this.state.nowDate
                            let statusStyle = cannotSignup ? 'signup-right signup-grey':'signup-right';
                            let statusText = cannotSignup ? '报名已结束':'查看';
                            let path = {
                                pathname: `/TusstarWeb/activity-detail/?id=${item.totalInfo.id}`,
                                state: item.totalInfo.id
                            }
                            return(
                                <div className="signup-item full-width flex-row" key={key}>
                                    <div className="signup-left">{item.title}
                                    <Text>{item.timeInfo}</Text>
                                    </div>
                                    <Link className="link-style-clear"  to ={path}>
                                    <div className={statusStyle}>{statusText}</div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activity: state.activity,
        sponsor: state.sponsor
    }
}
export default connect(mapStateToProps, null)(TopicDetail);