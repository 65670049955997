import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopicIndex from './TopicIndex'
class Topic extends Component {
    render() {
        return (
            <div>
                <TopicIndex topicInfoOri={this.props.topic}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        topic: state.topic
    }
}

export default connect(mapStateToProps, null)(Topic);