import Home from '../pages/home/Home';
import Recommend from '../pages/recommend/Recommend';
import Topic from '../pages/topic/Topic';
import Calendar from '../pages/calendar/Calendar';
import Rank from '../pages/rank/Rank';
import Find from '../pages/find/Find';
import User from '../pages/user/User';


let routes = [
    {
      path: "/",
      component: Home,
      name: "首页",
      exact: true
    },
    {
      path: "/TusstarWeb/recommend",
      component: Recommend,
      name: "推荐"
    },
    {
      path: "/TusstarWeb/topic",
      component: Topic,
      name: "专题"
    },
    {
      path: "/TusstarWeb/calendar",
      component: Calendar,
      name: "日历"
    },
    {
      path: "/TusstarWeb/rank",
      component: Rank,
      name: "排行榜"
    },
    {
      path: "/TusstarWeb/find",
      component: Find,
      name: "找活动"
    },
    {
      path: "/TusstarWeb/user",
      component: User,
      name: "我的"
    }
];

export default routes;
 
