import React, { Component } from 'react';
import './topic.css'
import TopicIndexHome from './TopicIndexHome';
import { connect } from 'react-redux';

class Topic extends Component {

    render() {
        return (
            <div className="topic-wrapper flex-column flex-center home-maxwidth">
                <TopicIndexHome alltheme={this.props.alltheme}/>
            </div>
        );
    }
}

//需要渲染什么数据
function mapStateToProps(state) {
    return {
        alltheme: state.topic
    }
}
export default connect(mapStateToProps, null)(Topic);