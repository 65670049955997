import React, { Component } from 'react';
import scoreImg from '../../../images/user/score.png';
import './userScore.css';
import {
    getScore,
    getUserDetails
  } from '../../../data.js';


class UserScore extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            scoreInfo: [],
            userScore: '',
            infoItem: [
                ['登陆','1分/每日首次登陆','1分'],
                ['查看活动', '1分/每个有效浏览', '6分'],
                ['报名活动', '3分/每个有效报名', '9分'],
                ['收藏活动', '1分/每个有效收藏', '3分'],
                ['分享活动', '1分/每个有效分享', '3分'],
                ['关注主办方', '1分/每个首次有效关注', '2分'],
                ['完善名片档案', '5分/首次完善名片档案', '5分'],
                ['查看图片直播', '1分/每个有效查看', '3分'],
                ['查看视频直播', '1分/每个有效查看', '3分'],
              ]
        }
    }

    componentDidMount() {
        this._initData()
    }

    _initData() {
        getScore().then(res => {
            var scoreInfo = res.data.data
            for (var i=0; i<scoreInfo.length ; i++ ){
              var ts = scoreInfo[i].timestamp
              var curDate = new Date(ts)
              var y1 = curDate.getFullYear()
              var m1 = curDate.getMonth() + 1
              var d1 = curDate.getDate()
              var h1 = curDate.getHours()
              var min1 = ('0' + curDate.getMinutes()).slice(-2)
              var timeInfo = y1 + '/' + m1 + '/' + d1 + ' ' + h1 + ':' + min1
              scoreInfo[i].timeInfo = timeInfo
            }
            this.setState({
              scoreInfo: scoreInfo,
            })
          })
        getUserDetails().then(res => {
            this.setState({
                userScore: res.data.data.score,
            })
        })
    }
    
    render() {
        let infoItem = this.state.infoItem
        return (
            <div className="full-width flex-row flex-twoside">
                <div className="score-left">
                    <div className="score-title">启迪之星活动积分说明</div>
                    <div className="text-wrapper">
                        <div className="text-line-1">
                            <div className="text-line-1-left">
                                操作
                            </div>
                            <div className="text-line-1-middle">
                                得分
                            </div>
                            <div className="text-line-1-right">
                                每日最高
                            </div>
                        </div>
                        {
                            this.state.infoItem.map((item, key) => {
                                if (key%2 == 0) {
                                    return (
                                        <div className="text-line-wrapper-1">
                                            <div className="text-line-2-left">
                                                {item[0]}
                                            </div>
                                            <div className="text-line-2-middle">
                                                {item[1]}
                                            </div>
                                            <div className="text-line-2-right">
                                                {item[2]}   
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="text-line-wrapper-2">
                                            <div className="text-line-2-left">
                                                {item[0]}
                                            </div>
                                            <div className="text-line-2-middle">
                                                {item[1]}   
                                            </div>
                                            <div className="text-line-2-right">
                                                {item[2]}
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div className="score-right">
                    <div className="score-title">积分明细</div>
                    <div className="score-bg-wrapper">
                        <div className="score-container">{this.state.userScore}</div>
                        <img src={scoreImg} alt="score-bg" className="score-img"/>
                    </div>
                    {
                        this.state.scoreInfo.map((item, key)=>{
                            return (
                                <div className="add-score-wrapper-block" key={key}>
                                    <div className="left-wrapper">
                                        <div className="time-wrapper">
                                        {item.timeInfo}
                                        </div>
                                        <div className="add-score-explain">
                                        {item.name}
                                        </div>
                                    </div>
                                    <div className="right-wrapper">
                                        <div className="first-text">
                                        + {item.score}
                                        </div>
                                        <div className="second-text">
                                        分
                                        </div>
                                    </div> 
                                </div>  
                            )  
                        })
                    }
                </div>
            </div>
        );
    }
}

export default UserScore;