import React, { Component } from 'react';
import './searchBox.css';
import searchLogo from '../../images/search-grey.png'
import searchLogo2 from '../../images/search-green.png'

class SearchBox extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            iptVal:'',
        };
    }
    iptChange = (e)=>{
        this.setState({
            iptVal: e.target.value
        },()=>{
            this.props.onChange(this.state.iptVal);
        });
    };

    render() {
        let iptClass= "search-box-1";
        if(this.props.type === "page"){
            iptClass= "search-box-2"
        }
        return (
            <div  className ={iptClass} >
                <input type="text" value={this.state.iptVal} 
                onChange={this.iptChange} />
                <img alt="search" src={this.props.type === "page"?searchLogo2:searchLogo}/>
            </div>
        );
    }
}

export default SearchBox;