import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Text from '../Text'
import '../../global.css'
import './activityItem.css'
import {likeSponsor, unLikeSponsor} from '../../data'

class ActivityItem extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            data: this.props.data,
            type: this.props.type, // 活动 or 主办方
        }
    }
    onLikeSponsor = ()=>{
        if(!localStorage.getItem('phone')){
            //TODO
            console.log("未登陆")
            this.loginAlert()
            //this.props.history.push('/TusstarWeb')
            return;
        }
        likeSponsor(this.state.data.id).then(res=>{
            if(res.data.message == "SUCCESS"){
                this.props.changeLikeSponsor(this.state.data.id)
                let newdata = this.state.data
                newdata.like = true
                this.setState({data: newdata})
            }else if(res.data.message == "已经关注过了"){
                let newdata = this.state.data
                newdata.like = true
                this.setState({data: newdata})
            }
            //this.props.getSponsor()
        })
    }
    onUnLikeSponsor = ()=>{
        unLikeSponsor(this.state.data.id).then(res=>{
            if(res.data.message == "SUCCESS"){
                this.props.changeLikeSponsor(this.state.data.id)
                let newdata = this.state.data
                newdata.like = false
                this.setState({data: newdata})
            }
        })
    }
    loginAlert = () =>{
        alert("请先登陆！！")
    }
    render() {
        let data = this.state.data;
        let  type = this.state.type;
        let status = data.status ? data.status: '';
        let actStatusBox;
        console.log('activityItem:', type, status)
        if (type==='sponsor'&& status ==='') {
            let status = data.like?'取消关注':'+ 关注'
            actStatusBox = (
                <div className="act-status-sponsor flex-column flex-around flex-y-center" onClick={data.like?this.onUnLikeSponsor:this.onLikeSponsor}>
                    <div className="act-signup">{status}</div>
                </div>
            );
        } else if (type==='sponsor') {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-cost">免费</div>
                    <div className="act-signup">取消关注</div>
                </div>
            );
        } else if (type==='activity' && status===1) {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-signup">查看</div>
                </div>
            );
        } else if (type==='activity' && status===2) {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-cost">待参加</div>
                    <div className="act-signup" >查看</div>
                </div>
            );
        } else if (type==='activity' && status===3) {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-signup">已参加</div>
                </div>
            );
        } else if (type==='activity' && status===-1) {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-cost">未通过</div>
                    <div className="act-signup" >查看</div>
                </div>
            );
        } else {
            actStatusBox = (
                <div className="act-status flex-column flex-around flex-y-center">
                    <div className="act-signup">查看</div>
                </div>
            );
        }
        let path = {}, imgPic;
        if(type === 'sponsor'){
            path={
                pathname: "/TusstarWeb/sponsor",
                state: data.id
            }
            imgPic = data.image
        }else if(type === 'topic'){  
            path={
                pathname: '/TusstarWeb/topic-detail',
                state: data
            }
            imgPic = data.image
        }else{  //type ==='activity'
            path={
                pathname: `/TusstarWeb/activity-detail/?id=${data.totalInfo.id}`,
                state: data.totalInfo.id
            }
            imgPic = data.totalInfo.activityPicture
        }
        let rankClass = this.props.idx === 0 ?'act-rank act-green':'act-rank act-grey'
        let rank = this.props.showRank?(
            <div className={rankClass}>
                    <Text className="rank-str">NO.</Text>
                    <Text className="rank-num">{parseInt(this.props.idx)+1}</Text>
                </div>
        ):''

        let desc;
        if(type === 'sponsor'){
            desc=(
                <div className="act-content flex-column">
                    <div className="act-title">{data.name}</div>
                    <div className="act-desc">历史活动{data.activitiesNumber}</div>
                    <div className="act-desc">可报名活动{data.canJoinActivitiesNumber}</div>
                    <div className="act-desc">粉丝{data.fansNumber}</div>
                </div>
            )
        }else{
            desc=(
                <div className="act-content flex-column">
                    <div className="act-title">{data.title}</div>
                    <div className="act-desc">{data.place}</div>
                    <div className="act-desc">{data.timeInfo}</div>
                    <div className="act-desc">{data.sponsor}</div>
                </div>
            )
        }
        if(type === 'sponsor'){
            return(
                <div className="act-wrapper flex-row">
                    <Link className="link-style-clear act-box flex-row" to={path}>
                        {rank}
                        <img src={imgPic} alt="" className="act-img"/>
                        {desc}
                    </Link>
                    {actStatusBox}
                </div>
            );
        }
        return (
            <Link className="link-style-clear flex-row" to={path}>
                <div className="act-wrapper flex-row">
                    {rank}
                    <img src={imgPic} alt="" className="act-img"/>
                    {desc}
                    {actStatusBox}
                </div>
            </Link>
        );
    }
}

//需要触发什么行为

function mapDispatchToProps(dispatch) {
    return {
        changeLikeSponsor: (sponsorId)=>{
            console.log("dispatch change_like_sponsor:", sponsorId)
            dispatch({
                type:"change_like_sponsor",
                sponsorId: sponsorId
            })
        },
        changeLikeAct: (actId)=>{
            console.log("dispatch change_like_act:", actId)
            dispatch({
                type:"change_like_act",
                actId: actId
            })
        }
    }
}

export default connect(null, mapDispatchToProps)(ActivityItem);
//export default ActivityItem;