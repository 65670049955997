import React, { Component } from 'react';
import './activityItem2.css'
import { Link } from 'react-router-dom';

/*
*九宫格样式
 */
class ActivityItem2 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            activity: this.props.activity,
            size: this.props.size
        }
    }
    
    render() {
        let activity = this.state.activity;
        let boxSize = 'find-box ';
        let boxSizeImg = 'find-img ';
        let boxContent = (
            <div className="find-content flex-column">
                    <div className="find-title">{activity.title}</div>
                    <div className="find-desc">{activity.totalInfo?activity.totalInfo.place:''}</div>
                    <div className="find-desc">{activity.timeInfo}</div>
                    <div className="find-desc">{activity.sponsor}</div>
                    <div className="find-signup">查看</div>
                </div>
        );
/*        if(this.state.size === "high"){
            boxSize += 'find-box-high'
        }else if(this.state.size === "short"){
            boxSize += 'find-box-short'
            boxSizeImg += 'find-img-full'
            boxContent = null
        }
*/  
/*    
        if(this.props.size === "high"){
            boxSize += 'find-box-high'
        }
        let key = this.props.idx;
        if(key === 3 || key === 5 || key === 7){
            boxSize += ' add-margin';             
        }
        if(key === 4){
            boxContent= null;
        }

*/
        let path, imgUrl;
        if(this.props.type === "topic"){
            path={
                pathname: '/TusstarWeb/topic-detail',
                state: activity
            }
            imgUrl= activity.image
        }else{
            path={
                pathname: `/TusstarWeb/activity-detail/?id=${activity.totalInfo.id}`,
                state:activity.totalInfo.id
            }
            imgUrl= activity.totalInfo.activityPicture
        }
        let price;
        if(this.props.type === "topic"){
            price=""
        }else if(activity.price === 0){
            price="免费"
        }else{
            price="¥"+activity.price
        }
        return (
            <div className={boxSize}>
                <div className="act-price">{price}</div>
                <Link className="link-style-clear" to={path}>
                    <img src={imgUrl} alt="" className={boxSizeImg}/>
                    { boxContent }
                </Link>
            </div>
        );
    }
}

export default ActivityItem2;