import React, { Component } from 'react';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import './findIndex.css';
import ActivityItem2 from "../../components/activityItem/ActivityItem2";



class FindIndex extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            navIdx: 0,
            activityInfoOri: this.props.activityInfoOri?this.props.activityInfoOri:[], 
            activityInfo:[],
            typeList: this.props.typeList?this.props.typeList:[],
            navList: []
        }
    }

    componentDidMount() {
        console.log("componentDidMount topic", this.state.activityInfoOri)
        let activityInfoOri = this.state.activityInfoOri;
        console.log("componentDidMount topic 1", this.state.typeList)
        let navList = this.getNavList(this.state.typeList)
        
        this.setState({
            activityInfoFilter: activityInfoOri,
            activityInfo: activityInfoOri,
            navList: navList
        })
    }

    getNavList(typeList){
        let navList = ['全部'];
        if(typeList){
            typeList.map((item, key)=>{ navList.push(item.name) })
        }
        return navList
    }

    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activityInfoOri
        console.log("componentWillReceiveProps topic 1", nextProps.typeList)
        const navList = this.getNavList(nextProps.typeList);
        console.log("newdata find", newdata);
        this.setState({
            activityInfoOri: newdata?newdata:[],
            navList: navList,
            activityInfo:[]
        },()=>{
            this.filterActFun(this.state.navIdx);
        })
    }
    onNavClick = (idx)=> {
        this.setState({
            navIdx:  parseInt(idx),
            activityInfo:[]
        },()=>{
            this.filterActFun(parseInt(idx));
        })
    }
    filterActFun = (idx) => {
        let activityInfoOri = this.state.activityInfoOri;
        if(idx === 0){
            this.setState({
                activityInfoFilter: activityInfoOri,
                activityInfo: activityInfoOri,
            })
            return 
        }
        // 过滤type
        var activityInfoFilter = activityInfoOri.filter(x => x.subtype === this.state.navList[idx])
        //this.state.activityInfo = activityInfoFilter;
        //this.state.activityInfoFilter = activityInfoFilter;
        this.setState({
            activityInfoFilter: activityInfoFilter,
            activityInfo: activityInfoFilter,
        })
    }

    render() {
        return (
            <div className="find-wrapper flex-column flex-center">
                <SubNavBar title={this.props.navTitle} navList={this.state.navList} navIdx="0"
                onNavClick={this.onNavClick}/>
                <div className="activity-item-2-wrapper flex-row flex-wrap">
                    {
                        
                        console.log("this.state.activityInfo:",this.state.activityInfo.length),

                        this.state.activityInfo.map((activity, key)=>{
                            //Type 1
                            /*let size = 'normal';
                            if(key === 4) {
                                size = 'short'
                            }else if (key === 3 || key ===5){
                                size = 'high'
                            }*/
                            //Type 2
                            let size = key%2 === 0 ? 'normal':'high'
                          //  console.log("key:",key)
                           // return "";
                          // console.log("item:", activity.title, key, key%2, size)
                            return (
                                <ActivityItem2 key={activity.title} activity={activity} size={size}/>
                            )
                        })

                      
                    }
                </div>
            </div>
        );
    }
}
export default FindIndex;