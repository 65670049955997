import React, { Component } from 'react';
import CalendarIndex from './CalendarIndex';
import { connect } from 'react-redux';

class Calendar extends Component {
    render() {
        return (
            <div className="page-padding">
                <CalendarIndex activityInfoOri={this.props.activity}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activity: state.activityByLoc
    }
}
export default connect(mapStateToProps, null)(Calendar);