import React, { Component } from 'react';
import './activityDetail.css';

import unlike from '../../images/like.png'
import like from '../../images/bottom-icon-2-like.png'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {likeSponsor, unLikeSponsor, likeAct, unLikeAct, viewAct, unapplyActivity} from '../../data'
import {getQueryString} from "../../utils/tools"
import {getSponsorList} from "../../data"
import { connect } from 'react-redux';
import Text from '../../components/Text'
import Msg from "../../components/Msg"
import { Link } from "react-router-dom";
//import { createHashHistory,createBrowserHistory } from 'history';
//const history = createHashHistory();

class ActivityDetail extends Component {
    constructor(props, context) {
        super(props, context);
        let actId = getQueryString("id");
        this.state={
            actId: actId,  //接收上个组件传来的值
            activity: {},
            totalInfo: {},
            imgIdx: 0,
            sponsorId: '',
            sponsorInfo: [],
            showQRCode: false,
        }
    }
    componentDidMount() {
        console.log("hi:", this.props.match.params)
        this._initData()
        viewAct(parseInt(this.state.actId))
        this.props.addViewAct(this.state.actId)
        sessionStorage.setItem("viewActId", this.state.actId)
        this._initData()
        console.log("match:", this.props.sponsor)
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activityInfoOri
        let actId = getQueryString("id");
        this.setState({
            newdata: newdata,
            actId: actId
        },()=>{
            this._initData()
        })
    }
    getSponsorInfo=(id)=>{
        let sponsorInfo = this.props.sponsor.find(sponsor=>sponsor.id === id)
        return sponsorInfo;
    }
    getActivityInfo=(id)=>{
        let activity = this.props.activity.find(actItem =>  actItem.totalInfo.id === parseInt(id))
        return activity
    }
    _initData = ()=>{
        let activity = this.getActivityInfo(this.state.actId)
        if(activity){
            let sponsorInfo = this.getSponsorInfo(activity.totalInfo.sponsorId)
            this.setState({
                activity: activity,
                totalInfo: activity.totalInfo,
                sponsorInfo: sponsorInfo
            })
        }
    }
    onLikeSponsor = ()=>{
        if(!localStorage.getItem('phone')){
            //TODO
            console.log("未登陆")
            this.loginAlert()
            //this.props.history.push('/TusstarWeb')
            return;
        }
        likeSponsor(this.state.activity.totalInfo.sponsorId).then(res=>{
            if(res.data.message == "SUCCESS"){
                this.props.changeLikeSponsor(this.state.activity.totalInfo.sponsorId)
            }
            //this.props.getSponsor()
        })
    }
    onUnLikeSponsor = ()=>{
        unLikeSponsor(this.state.activity.totalInfo.sponsorId).then(res=>{
            if(res.data.message == "SUCCESS"){
                this.props.changeLikeSponsor(this.state.activity.totalInfo.sponsorId)
            }
        })
    }
    onLikeAct = ()=>{
        if(!localStorage.getItem('phone')){
            //TODO
            console.log("未登陆")
            this.loginAlert()
            //this.props.history.push('/TusstarWeb')
            return;
        }
        likeAct(this.state.activity.totalInfo.id).then(res=>{
            console.log("res:", res);
            if(res.data.message === "SUCCESS"){
                this.props.changeLikeAct(this.state.activity.totalInfo.id)
            }
            //this.props.getSponsor()
        })
    }
    onUnLikeAct = ()=>{
        unLikeAct(this.state.activity.totalInfo.id).then(res=>{
            if(res.data.message == "SUCCESS"){
                this.props.changeLikeAct(this.state.activity.totalInfo.id)
            }
        })
    }
    loginAlert = () =>{
        alert("请先登陆！")
    }
    cancelSignup = ()=>{
        confirmAlert({
            title: '取消报名',
            message: '你确定取消报名吗？',
            buttons: [
              {
                label: '确定取消',
                onClick: () => {
                    console.log("确定取消");
                    this.noAttend()
                }
              },
              {
                label: '容我想想',
                onClick: () => {
                    console.log("取消取消");
                }
              }
            ]
        });
    }
    noAttend = () =>{
        var id = this.state.totalInfo.id
        var phone = localStorage.getItem('phone')
        unapplyActivity(phone, id).then(res => {
            if(res.data.message === 'SUCCESS'){
                this.props.changeAttendStatus(this.state.activity.totalInfo.id, 0)
            }else{
                alert("取消失败，请重试")
            }
        })
    }
    popQr = ()=>{
        this.setState({ showQRCode : true})
    }
    closeQr = () => {
        this.setState({ showQRCode: false })
    }
    render() {
        let activity = this.state.activity;
        let totalInfo = this.state.totalInfo;
        let imgSelect = totalInfo.playBill;
        let showQRCode = this.state.showQRCode;
        if(totalInfo.descriptionStr){
            totalInfo.description = JSON.parse(totalInfo.descriptionStr)
        }
        
       
        //[this.state.imgIdx];
        /*let imgList = totalInfo.activityPicture.map((img, key)=>{
            return (
                <img src={img} alt="" className="img-samll" key={key}/>
            )
        });*/ 

        console.log("ActivityDetail render", this.props.sponsor, totalInfo.sponsorId);
        let sponsorInfo = this.props.sponsor.find(x => x.id === totalInfo.sponsorId);
        console.log("sponsorInfo", sponsorInfo);
        let likeSponsor =''
        if(!sponsorInfo){
            likeSponsor = <div className="part3-btn" onClick={this.onLikeSponsor} style={{ cursor: "pointer" }}>+关注</div>
        }else if(sponsorInfo.like){
            likeSponsor = <div className="part3-btn part3-btn-grey" onClick={this.onUnLikeSponsor} style={{ cursor: "pointer" }}>已关注</div>
        }else{
            likeSponsor = <div className="part3-btn" onClick={this.onLikeSponsor} style={{ cursor: "pointer" }}>+关注</div>
        }
        let likeAct =''
        if(totalInfo.like){
            likeAct = (
                <div style={{ cursor: "pointer" }} onClick={this.onUnLikeAct} className="part1-right-btn-like flex-row  flex-x-center flex-y-center">
                    <img src={like} alt="like" className="part1-btn-icon"/>
                    <div><Text> 已收藏</Text></div>
                </div>
            )
        }else{
            likeAct = (
                <div style={{ cursor: "pointer" }} onClick={this.onLikeAct} className="part1-right-btn-like flex-row  flex-x-center flex-y-center">
                    <img src={unlike} alt="unlike" className="part1-btn-icon"/>
                    <div><Text> 添加收藏</Text></div>
                </div>
            )
        }
        let signupPath = {
            pathname: '/TusstarWeb/signup',
            state: totalInfo.id
        }
        let sponsorPath = {
            pathname: '/TusstarWeb/sponsor',
            state: totalInfo.sponsorId
        }

        let signup = ''
        let nowDate = new Date().getTime()
        console.log("sign:", nowDate, totalInfo.beginTime, totalInfo.beginTime <= nowDate)
        if(totalInfo.canJoinTime <= nowDate){
            signup = (<div className="signup-btn signup-white"><Text>已过期</Text></div>)
        }else if(!localStorage.getItem("phone")){
            signup = (<div className="signup-btn signup-green" onClick={this.loginAlert}><Text>我要报名</Text></div>)
        }else{
            switch(totalInfo.attend) {
                case 0:
                    signup = (<Link className="link-style-clear" to={signupPath}>
                        <div className="signup-btn signup-green"><Text>我要报名</Text></div>
                    </Link>)
                   break;
                case 1:
                   signup = (<div className="signup-btn signup-white" onClick={this.cancelSignup}><Text>已报名（撤销）</Text></div>
                   );
                   break;
                case 2:
                    signup = (<div className="signup-btn signup-white" onClick={this.cancelSignup}><Text>审核通过（撤销）</Text></div>);
                    break;
                case 3:
                    signup = (<div className="signup-btn signup-white" onClick={this.cancelSignup}><Text>已参加（撤销）</Text></div>);
                    break;
                case -1:
                    signup = (<Link className="link-style-clear" to={signupPath}>
                        <div className="signup-btn signup-white signup-reject" ><Text>已拒绝（再次提交）</Text></div>
                        </Link>);
                    break;
                default:
                    signup = (<Link className="link-style-clear" to={signupPath}>
                        <div className="signup-btn signup-green" ><Text>我要报名</Text></div>
                    </Link>)
        }
       } 

       
        let picLink = totalInfo.pictureLiveLinkWeb, vedioLink = totalInfo.videoLiveLinkWeb, picBlock, videoBlock, qrBlock, qrBlockPop
       if(picLink){
            picBlock = (
                <a href={picLink} target="_blank" rel="noopener noreferrer">
                    <div className="btn-type1" onClick={this.imgLive}>图片直播</div>
                </a>
            )
       }else{
            picBlock = (<div className="btn-type2" onClick={this.imgLive}>图片直播</div>)
       }
       if(vedioLink){
            videoBlock  = (
                <a href={vedioLink} target="_blank" rel="noopener noreferrer">
                    <div className="btn-type1" onClick={this.videoLive}>视频直播</div>
                </a>
            )
       }else{
            videoBlock  = <div className="btn-type2" onClick={this.videoLive}>视频直播</div>
        }
        qrBlock = <div className="btn-type1" onClick={this.popQr}>二维码</div>
       let activityDetail = totalInfo.description?totalInfo.description.descriptions.map((value, key)=>{
           if(value.type === 'text'){
                let c = value.styles.filter(Boolean).join(" ")
                console.log("class:", c)
                return (<div className={c} key={key}><Msg text={value.content} /></div>)
           }
           else if(value.type === 'img'){
                return (<div key={key}><img className="detail-img" src={value.content}/></div>)
           }
       }):<Msg text={totalInfo.detail} />
        qrBlockPop= null;
        if (showQRCode){
            let imgblock = "http://qr.topscan.com/api.php?&w=200&text=" + "https://oss.matrixsci.cn/?dataItem=" + this.state.actId
            qrBlockPop = (
                <div>
                    <div className="dialog-face"></div>
                    <div className="dialog-root">
                        <div className="dialog-wrapper">
                            <div className="close-btn" onClick={this.closeQr}>×</div>
                            <img className="pop-img" src={imgblock} />
                        </div>
                    </div>
                </div>
            )
        }
       
        return (
            <div className="page-padding full-width">
                <div className="part1  flex-row flex-twoside">
                    <div className="part1-left flex-column">
                        <img src={imgSelect} alt="" className="part1-left-main-pic"/>
                        <div className="part1-left-piclist flex-row">
                            {/*imgListimgSelect*/}
                        </div>
                    </div>
                    <div className="part1-right flex-column">
                        <div className="part1-right-title">{activity.title}</div>
                        <div className="part1-right-cost">¥{activity.price}</div>
                        <div className="part1-right-number">
                            {totalInfo.views}浏览  |
                            {totalInfo.likeNumber}收藏  |
                            {totalInfo.attendNumber}报名  |
                        </div>
                        <div className="part1-right-detail">
                            <p>活动时间：{activity.timeInfo}</p>
                            <p>活动地址：{activity.totalInfo?activity.totalInfo.place:''}</p>
                            <p>活动联系人：{totalInfo.liaisonMan}</p>
                            <p>联系方式：{totalInfo.phone}</p>
                        </div>
                        <div className="part1-right-btn-wrapper flex-row">
                            {/*<div className="part1-right-btn-share flex-row flex-x-center flex-y-center">
                                <img src={share} alt="share" className="part1-btn-icon"/>
                                <div><Text>  分享</Text></div>
                            /div>*/}
                            {likeAct}
                            {signup}
                        </div>
                    </div>
                </div>
                {showQRCode? qrBlockPop:''}
                <div className="part2 full-width flex-row flex-twoside">
                    {picBlock}
                    {videoBlock}
                    {qrBlock}
                </div>
                <div className="part3 full-width flex-row">
                    <div className="par3-title">{this.state.sponsor}</div>
                    <div className="part3-number">
                        <Text>粉丝 {sponsorInfo?sponsorInfo.likeNumber:''} | 
                        活动 {sponsorInfo?sponsorInfo.activitiesNumber:''} </Text>
                    </div>
                    <div className="part3-btn-wrapper flex-row flex-x-center flex-y-center">
                        {likeSponsor}
                        <Link className="link-style-clear" to={sponsorPath}>
                            <div className="part3-btn" style={{ cursor: "pointer" }}>主办方小站</div>
                        </Link>
                    </div>
                </div>
                <div className="part4">
                    <div className="part4-title">活动详情</div>
                    <div className="part4-content">
                    {/*<Msg text={totalInfo.detail} />*/}
                    {activityDetail}
                    </div>
                </div>
        </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        sponsor:state.sponsor,
        activity: state.activity
    }
}
//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        getSponsor: () => {
          getSponsorList().then(res=>{
            console.log("mapDispatchToProps getSponsor:",res.data.data)
            dispatch({
              type:"get_sponsor",
              sponsor: res.data.data
            })
          })
        },
        changeLikeSponsor: (sponsorId)=>{
            console.log("dispatch change_like_sponsor:", sponsorId)
            dispatch({
                type:"change_like_sponsor",
                sponsorId: sponsorId
            })
        },
        changeLikeAct: (actId)=>{
            console.log("dispatch change_like_act:", actId)
            dispatch({
                type:"change_like_act",
                actId: actId
            })
        },
        changeAttendStatus: (actId, status) => {
            dispatch({
              type:"change_attend_status",
              data: {
                  actId: actId,
                  status: status
              }
            })
        },
        addViewAct: (actId)=>{
            console.log("dispatch addViewAct:", actId)
            dispatch({
                type:"add_view_act",
                actId: actId
            })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetail);