import React, { Component } from 'react';
import Calender from '../../components/calendar/Calendar'
import ActivityItem from '../../components/activityItem/ActivityItem'

import './calendarIndex.css'

let tempImage='https://matrixsci.oss-cn-beijing.aliyuncs.com/ned/citytips/citytips-banner-1-Ootmarsum.jpg';

class CalendarIndex extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            activityInfoOri: this.props.activityInfoOri?this.props.activityInfoOri:[], 
            activityInfo:[],
        };
    }

    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activityInfoOri
        console.log("newdata", newdata);
        if(newdata !== undefined){
            this.setState({
                activityInfoOri: newdata,
            },()=>{
                this.onChangeDay();
            })
        }
    }

    onChangeDay = (dateStr)=>{
        console.log("time", dateStr);
        var dateStartTs = new Date(dateStr).valueOf();
        var dateEndTs = dateStartTs + 24 * 60 * 60 * 1000
        console.log('日历', dateStr, dateStartTs, dateEndTs)
        var activityInfo = this.state.activityInfoOri.filter(x =>  x.totalInfo.beginTime >= dateStartTs && x.totalInfo.beginTime < dateEndTs)
        this.setState({activityInfo: activityInfo})
    }
    
    render() {
        return (
            <div className="calendar-wrapper flex-row page-min-height">
                <div className="calendar-box">
                    <Calender onChangeDay={this.onChangeDay} activityInfo={this.state.activityInfoOri}/>  
                </div> 
                <div className="activity-box full-width">
                    {
                        this.state.activityInfo.map((activity, key)=>{
                            return (
                                <ActivityItem key={activity.title} data={activity} type="activity"/>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default CalendarIndex;