import React, { Component } from 'react';
import ActivityItem2 from '../../components/activityItem/ActivityItem2';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import './SponsorIndex.css';

const navTitle ='主办方';
const navList = ['可报名','已过期'];
class SponsorIndex extends Component {
    constructor(props) {
        super(props);
        this.state={
            activityInfoOri: this.props.activityInfoOri?this.props.activityInfoOri:[],
            canSignUp: true,
            avaiActInfo: [],
            unavaiActInfo: [],
            sponsorName: ''
        }
    }

    componentDidMount() {
        this.filterSponsor()
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activityInfoOri
        console.log("newdata find", newdata);
        this.setState({
            activityInfoOri: newdata,
        },()=>{
            this.filterSponsor();
        })
    }
    filterSponsor = ()=>{
        var filterActInfo = this.state.activityInfoOri.filter(x => {
            //console.log("filterSponsor~~:", x.title, x.sponsor, x.totalInfo.sponsorId, this.props.sponsorInfo)
            return parseInt(x.totalInfo.sponsorId) === parseInt(this.props.sponsorInfo)
        })
        var curTime = new Date().getTime()
        var avaiActInfo = filterActInfo.filter(x => x.totalInfo.canJoinTime > curTime)
        var unavaiActInfo = filterActInfo.filter(x => x.totalInfo.canJoinTime <= curTime)
        //console.log("filterSponsor:", curTime, avaiActInfo, unavaiActInfo)
        var sponsorName = this.getSponsorName()
        this.setState({
            avaiActInfo: avaiActInfo,
            unavaiActInfo: unavaiActInfo,
            sponsorName: sponsorName
        })
    }
    getSponsorName = ()=>{
        let sponsor = this.props.sponsor.find(x=> x.id === this.props.sponsorInfo )
        return sponsor?sponsor.name:''
    }
    onNavClick = (idx)=> {
        this.setState({
            navIdx:  parseInt(idx),
        })
        console.log("onNavClick", idx);
        switch (parseInt(idx)){
            case 0:
                this.setState({
                    canSignUp: true
                });
                break;   
            case 1:
                this.setState({
                    canSignUp: false
                });
                break;   
            default:
        }
    }
    
    render() {
        let avaiActInfoBlock = this.state.avaiActInfo.map((activity, key)=>{
            return (
                <ActivityItem2 key={activity.title} activity={activity} idx={key}/>
            )
        })
        let unavaiActInfoBlock = this.state.unavaiActInfo.map((activity, key)=>{
            return (
                <ActivityItem2 key={activity.title} activity={activity} idx={key}/>
            )
        })
        return (
            <div className="activity-wrapper flex-column flex-center">
                <SubNavBar title={this.state.sponsorName} navList={navList} navIdx="0" 
                onNavClick={this.onNavClick}/>
                <div className="flex-row flex-wrap flex-y-start" style={{"width": "100%"}}>
                    { this.state.canSignUp ? avaiActInfoBlock: unavaiActInfoBlock}
                </div>
            </div>
        );
    }
}

export default SponsorIndex;