import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActivityItem from '../../../components/activityItem/ActivityItem';

let tempImage='https://matrixsci.oss-cn-beijing.aliyuncs.com/ned/citytips/citytips-banner-1-Ootmarsum.jpg';

class UserLike extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
        }
    }
    
    render() {
        let activity = this.props.activity
        console.log('我的收藏：', activity)
        let myact = activity.filter(x => x.totalInfo.like)
        
        return (
            <div className="flex-column">
                {
                    myact.map((activity, key)=>{
                        return (
                            <ActivityItem key={key} data={activity} type="activity"/>
                        )
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sponsor: state.sponsor,
        activity: state.activity,
        user: state.user
    }
}


export default connect(mapStateToProps, null)(UserLike);