import React, { Component } from 'react';
import locaList from '../../model/location'

class HeaderLoc extends Component {
    constructor(props) {
        super(props);
        this.state={
            locIndex: localStorage.getItem("locIndex")?localStorage.getItem("locIndex"):0,
        }
    }
    
  onChangeLoc(newlocIndex){
    console.log("$$$$:", newlocIndex);
    this.setState({
      locIndex: newlocIndex
    })
    localStorage.setItem("locIndex", newlocIndex);
    localStorage.setItem("currentCity", locaList[newlocIndex]);
    this.props.setActivityByLoc(newlocIndex)
  }
  onMouseEnterLoc = ()=> {
    this.setState({
      hoverLoc: true,
    });
  }
  onMouseLeaveLoc = ()=>{
    this.setState({
      hoverLoc: false,
    });   
  }
    render() {
        let locHoverBlock= (
            <div className="loc-hover flex-row flex-wrap flex-y-center">
              {
                locaList.map((loc, key)=>{
                  return (
                    <div key={key} onClick={this.onChangeLoc.bind(this, key)}>{loc}</div>
                  )
                })
              }
            </div>
          )
        return (
            <div style={{ cursor: "pointer" }} onMouseEnter={this.onMouseEnterLoc} onMouseLeave={this.onMouseLeaveLoc}>   
              {locaList[this.state.locIndex]}
              {this.state.hoverLoc?locHoverBlock:''}
            </div>
        );
    }
}

export default HeaderLoc;