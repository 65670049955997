import ActivityDetail from '../pages/activityDetail/ActivityDetail'
import TopicDetail from '../pages/activityDetail/TopicDetail'
import Search from '../pages/search/Search'
import Signup from '../pages/signup/Signup'
import Sponsor from '../pages/sponsor/Sponsor'
import Register from '../pages/register/Register'


let routes2 = [
    {
      path: "/TusstarWeb/activity-detail",
      component: ActivityDetail,
      name: "活动详情",
    },
    {
      path: "/TusstarWeb/topic-detail",
      component: TopicDetail,
      name: "主题详情",
    },
    {
      path: "/TusstarWeb/search",
      component: Search,
      name: "搜索",
    },
    {
      path: "/TusstarWeb/signup",
      component: Signup,
      name: "报名",
    },
    {
      path: "/TusstarWeb/sponsor",
      component: Sponsor,
      name: "主办方小站",
    },
    {
      path: "/TusstarWeb/register",
      component: Register,
      name: "用户注册",
    },
];

export default routes2;
 
