import React, { Component } from 'react';
import Login from './Login';
import '../../../global.css';
import './welcome.css';
import SliderBox from './SliderBox'
import { connect } from 'react-redux';

class Welcome extends Component {
    render() {
        let loginStatus = this.props.loginStatus
        if(loginStatus){
            return (
                <div className="welcome flex-row">
                    <div className="slider">
                        <SliderBox ads={this.props.ads} />
                    </div>  
                    <div className="qrcode">
                        <img src="https://tusstaross.oss-cn-beijing.aliyuncs.com/qrcode.jpg"/>
                    </div>  
                </div>
            );
        }else{
            return (
                <div className="welcome">
                    <div className="welcome-content flex-row">
                        <div className="slider">
                            <SliderBox ads={this.props.ads}/>
                        </div>  
                        <div className="login">
                            <Login />
                        </div>  
                    </div>
                </div>
            );
        }
    }
}
function mapStateToProps(state) {
    return {
        ads: state.ads,
        loginStatus: state.loginStatus
    }
}
export default connect(mapStateToProps, null)(Welcome);