import React, { Component } from 'react';
import { connect } from 'react-redux'
import SponsorItem from '../../../components/activityItem/SponsorItem';

class UserSponsor extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            selectIdx: 0,
            sponsorData: []
        }
    }

    componentDidMount() {
        let sponsorInfo = this.props.sponsorInfo
        if(sponsorInfo){
            this.setState({
                sponsorData: sponsorInfo.filter(x => x.like),
            })
        }
    }
    
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.sponsorInfo
        this.setState({
            sponsorInfo: newdata,
            sponsorData: newdata?newdata.filter(x => x.like):[],
        })
    }
    render() {
        console.log('关注的主办方: ', this.state.sponsorData)
        return (
            <div className="full-width flex-column ">
                {
                    this.state.sponsorData.map((sponsor, key)=>{
                        return (
                            <SponsorItem key={sponsor.id} data={sponsor} type="sponsor"/>
                        )
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activity: state.activity,
        user: state.user
    }
}


export default connect(mapStateToProps, null)(UserSponsor);