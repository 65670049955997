import React, { Component } from 'react';
import { connect } from 'react-redux'
import ActivityItem from '../../../components/activityItem/ActivityItem';

import './userSignup.css';
let tempImage='https://matrixsci.oss-cn-beijing.aliyuncs.com/ned/citytips/citytips-banner-1-Ootmarsum.jpg';

class UserSignup extends Component {
    constructor(props, context) {
        super(props, context);
        //Todo fake data end
        this.state={
            selectIdx: 0,
            activityData: [],
            activityDataOri: []
        }
    }

    componentDidMount() {
        console.log("user componentDidMount length:", this.props.activity.length)
        let act = this.props.activity
        this.setState({
            activityData: act.filter(x => x.totalInfo.attend !== 0),
            activityDataOri: act.filter(x => x.totalInfo.attend !== 0)
        })
    }

    gotoActivity = (actId) =>{
        console.log("gotoActivity id:", actId)
    }

    resubmit = (actId) =>{
        console.log("resubmit id:", actId)
    }

    cancel = (actId)=>{
        console.log("cancel id:", actId)
    }

    isExipre = (x)=>{
        return Date.parse(new Date()) > x.totalInfo.endTime; 
    }
    onSelectChange = (idx)=>{
        var activityInfoOri = this.state.activityDataOri
        var activityInfo = []
        console.log('我的报名: ', idx, activityInfoOri)
        switch(parseInt(idx)) {
          case 1:
              //审核中
                activityInfo = activityInfoOri.filter(x => x.totalInfo.attend === 1 && !this.isExipre(x))
            break
          case 2:
              //已审核通过
                activityInfo = activityInfoOri.filter(x => x.totalInfo.attend === 2 && !this.isExipre(x))
            break
          case 3:
              //已参加
                activityInfo = activityInfoOri.filter(x => (x.totalInfo.attend === 2 && this.isExipre(x))||(x.totalInfo.attend !== 0 && x.totalInfo.attend !== 1 && x.totalInfo.attend !== 2 && x.totalInfo.attend !== -1))
            break
          case 4:
              //审核未通过
                activityInfo = activityInfoOri.filter(x => x.totalInfo.attend === -1 || (x.totalInfo.attend === 1 && this.isExipre(x)))
            break
          default:
            activityInfo = activityInfoOri
        }
        this.setState({
            selectIdx: idx,
            activityData: activityInfo
        });
    }
    
    render() {
        let act = this.state.activityData
        return (
            <div>
                <Banner onclick={this.onSelectChange}/>
                {
                    act.map((activity, key)=>{
                        return (
                            <ActivityItem key={activity.totalInfo.id} data={activity} 
                            type="activity" 
                            gotoActivity={this.gotoActivity}
                            resubmit={this.resubmit}
                            cancel={this.cancel}/>
                        )
                    })
                }
            </div>
        );
    }
}

class Banner extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            title: ["全部", "审核中","待参加","已参加","未通过"],
            selectIdx: 0
        }
    }
    onSelect = (e)=>{
        let idx = parseInt(e.currentTarget.dataset.idx);
        this.props.onclick(idx);
        this.setState({
            selectIdx: idx
        })
    }
    render() {
        return (
            <div className="signup-banner full-width flex-row">
                {
                    this.state.title.map ((title, key) => {
                        let titleClass = 'signup-title';
                        if(this.state.selectIdx === 0){
                            titleClass += ' signup-title-first'
                        }
                        if(this.state.selectIdx === key){
                            titleClass += ' signup-title-select'
                        }
                        return (
                            <div style={{ cursor: "pointer" }} className={this.state.selectIdx === key ? "signup-title signup-title-select": "signup-title"}
                            onClick={this.onSelect} key={key} data-idx={key}>
                            {title}</div>
                        )
                    })
                }
            </div>  
        );
    }
}

function mapStateToProps(state) {
    return {
        sponsor: state.sponsor,
        activity: state.activity,
        user: state.user
    }
}


export default connect(mapStateToProps, null)(UserSignup);