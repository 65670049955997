import React, { Component } from 'react';
import SponsorIndex from './SponsorIndex'
import { connect } from 'react-redux';

class Sponsor extends Component {
    constructor(props) {
        super(props);
        if(this.props.location.state){
            sessionStorage.setItem("sponId", this.props.location.state)
        }
    }
    
    render() {
        let sponsorInfo = parseInt(sessionStorage.getItem("sponId"))
        return (
            <div className="page-padding page-min-height">
                <SponsorIndex 
                    activityInfoOri={this.props.activity}
                    sponsorInfo={sponsorInfo}
                    sponsor={this.props.sponsor}/>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        activity: state.activity,
        sponsor: state.sponsor
    }
}
export default connect(mapStateToProps, null)(Sponsor);