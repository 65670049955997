import React, { Component } from 'react';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import Select from '../../components/select/Select'
import ActivityItem2 from '../../components/activityItem/ActivityItem2'
import locaList from '../../model/location'

import './topicIndex.css'

const navTitle ='专题活动';

const options1 = locaList.map((item, key)=>{
    return { value: key, label: item }
})
const options2 = [
    { value: 0, label: '热度榜' },
    { value: 1, label: '浏览榜' },
];
class TopicIndex extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            selectIndex: [0, 0],
            filterInfo: [0, 0],
            topicInfoOri: this.props.topicInfoOri,
            topicInfo:[], //筛选后展示的数据
        }
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.topicInfoOri
        this.setState({
            topicInfoOri: newdata,
        },()=>{
            this.filterActFun(this.state.navIdx);
        })
    }
    componentDidMount(){
        this.filterActFun();
    }

    filterActFun= ()=> {
        var topicInfoOri = this.state.topicInfoOri
        var filterInfo = this.state.filterInfo
        console.log('###: ', filterInfo)
        // 过滤city
        var locationidx = filterInfo[0]
        var topicInfo0 = topicInfoOri
        if(locationidx !== 0){
            topicInfo0 = topicInfoOri.filter(x => x.cityId == locationidx+1)
        }
        console.log('#####', topicInfoOri, filterInfo, topicInfo0)
        // 排序
        var sortidx = filterInfo[1]
        var topicInfo1 = []
        switch (sortidx) {
          case 1:
            topicInfo1 = topicInfo0.sort((a,b) => b.views - a.views )
            break
          case 0:
            topicInfo1 = topicInfo0.sort((a, b) => b.joinUserCount - a.joinUserCount)
            break
          default:
            break
        }
        this.setState({
            topicInfo: topicInfo1
        })
      }
    
    
    handleChange = (selectedOption, idx) => {
        console.log("selectedOption", selectedOption, idx);
        let newSelectIndex = this.state.selectIndex;
        newSelectIndex[idx] = parseInt(selectedOption);
        this.setState({
            selectIndex: newSelectIndex,
            filterInfo: newSelectIndex
        },()=>{
            this.filterActFun()
        });
    };

    render() {
        const selectIndex = this.state.selectIndex;
        return (
            <div className="theme-wrapper page-padding flex-center">
                <SubNavBar title={navTitle}/>

                <div className="select-wrapper flex-row flex-center">
                    <Select className="select select-border" idx={0} value={selectIndex[0]} 
                    onChange={this.handleChange} options={options1} />
                    <div>|</div>
                    <Select className="select" idx={1} value={selectIndex[1]} 
                    onChange={this.handleChange} options={options2} />
                </div>
                <div className="activity-item-2-wrapper flex-row flex-wrap flex-y-start">
                    {
                        this.state.topicInfo.map((activity, key)=>{
                            let size = key%2 === 0 ? 'normal':'high'
                            return (
                                <ActivityItem2 key={activity.title} activity={activity} size={size}
                                type="topic" idx={key}/>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

export default TopicIndex;