import React, { Component } from 'react';
import RecomIndex from "./RecomIndex"
import { connect } from 'react-redux';
import {notExpireAct} from '../../utils/tools'

class Recommend extends Component {

    render() {
        return (
            <div className="page-padding">
                <RecomIndex 
                    history={this.props.history}
                    activityInfoOri={this.props.activityByLoc}
                    activityByLoc={this.props.activityByLoc}
                    navTitle='活动推荐'/>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        activityByLoc: state.activityByLoc
    }
}


export default connect(mapStateToProps, null)(Recommend);