import React, { Component } from 'react';
import Slider from "react-slick";
import './sliderBox.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SliderBox extends Component {
    render() {
        let adsList = this.props.ads? this.props.ads.images:'[]'
        console.log("adsList:", adsList);
        let sliderBlock = JSON.parse(adsList).map((item, key)=>{
            if(this.props.type === "full"){
                return (
                    <div key={key}>
                        <div className="slider-box">
                            <img className="slider-left" src={item.url} key={key} alt={'ads'}/>
                            <div className="slider-right">{item.description}</div>
                        </div>
                    </div>
                )
            }else{
                return (<div key={key}>
                <img  className="slider-ads" src={item.url} key={key} alt={'ads'}/>
                </div>)
            }
        })
        var settings = {
            dots: true,
            infinite: true,
            autoplaySpeed: 2000,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                {sliderBlock}
            </Slider >
        );
    }
}

export default SliderBox;