import React, { Component } from 'react';
import './calender.css';
import leftArrow from '../../images/left-arrow.png';
import rightArrow from '../../images/right-arrow.png';

class Calendar extends Component {

    constructor(props) {
        super(props);
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        this.state = {
            currentDay: day,
            currentMonth: month, 
            currentYear: year, 
            activityInfo: this.props.activityInfo,
            weekList: [
                {name: 'S', className: ''},
                {name: 'M', className: ''},
                {name: 'T', className: ''},
                {name: 'W', className: ''},
                {name: 'T', className: ''},
                {name: 'F', className: ''},
                {name: 'S', className: ''}
            ],
            dayList: []
        }

        this.initCalendar = this.initCalendar.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.preMonth = this.preMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
    }

    componentDidMount() {
        let now = new Date();
        this.initCalendar(now);
    }
    componentWillReceiveProps(nextProps) {
        const newdata = nextProps.activityInfo
        console.log("newdata calendar", newdata);
        this.setState({activityInfo: newdata},()=>{
            this._initActHint();
        })
    }
    // 获取当前date的当月第一天的字符串形式
    getMonthFirstDate(date) {
        let nowYear = date.getFullYear(); // 获取年份
        let nowMonth = date.getMonth()+1; // 获取月份
        return  `${nowYear}/${nowMonth}/01`
    }

    // 获取当前date的字符串形式
    getDateString(date) {
        let nowYear = date.getFullYear(); // 获取年份
        let nowMonth = date.getMonth()+1; // 获取月份
        let day = date.getDate();
        day = day < 10 ? '0' + day : day;
        return  `${nowYear}/${nowMonth}/${day}`
    }

    // 上个月
    preMonth() {
        let date = new Date(`${this.state.currentYear}-${this.state.currentMonth}-${this.state.currentDay}`)
        let preMonthFirstDate = new Date(this.getMonthFirstDate(new Date(date.setDate(0)))); // 0 是上个月最后一天
        this.initCalendar(preMonthFirstDate)
    }

    // 下个月
    nextMonth() {
        let date = new Date(`${this.state.currentYear}-${this.state.currentMonth}-${this.state.currentDay}`)
        let nextMonthFirstDate = new Date(this.getMonthFirstDate(new Date(date.setDate(33))));
        this.initCalendar(nextMonthFirstDate)
    }

      // 初始化日历
    initCalendar(currentDate) {
        let nowDate = currentDate ? currentDate : new Date();
        let nowMonthFirstDate = this.getMonthFirstDate(nowDate) // 获取当月1号日期
        let nowWeek = new Date(nowMonthFirstDate).getDay() //? new Date(nowMonthFirstDate).getDay() : 7; // 获取星期
        let newDateList = []; // 创建日期数组
        let startDay =  1 - nowWeek; // 开始日期的下标  以为 setDate(0)是上个月最后一天  所以是2-nowWeek
        let showDayLength = nowWeek < 6 ? 35 : 42;  // 如果5行能显示下一个月 就只显示5行
        // 循环处理 获取日历上应该显示的日期
        let isCurrentMonth = false;
        for (let i = startDay; i < startDay + showDayLength; i++) {
            let date = new Date(new Date(nowMonthFirstDate).setDate(i)); // 获取时间对象
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 小于9的数字前面加0
            if(day+'' === "01"){
                isCurrentMonth = !isCurrentMonth;
            }
            let dayObject = {
                date: this.getDateString(date),
                day,
                className: isCurrentMonth?'':'disable',
            }
            // new Date(str).toDateString() === new Date().toDateString()
            if (date.toDateString() === new Date().toDateString()) {
                dayObject.className = 'today'
            }
            newDateList.push(dayObject)
        }
        this.setState((pre) => {
            return {
                dayList: newDateList,
                currentDay: nowDate.getDate(),
                currentMonth: nowDate.getMonth() + 1 >= 10 ? nowDate.getMonth() + 1 : '0' + (nowDate.getMonth() + 1),
                currentYear: nowDate.getFullYear(),
            }
        },()=>{
            this._initActHint()
        })

    }

    _initActHint = () => {
     let activityInfo  = this.state.activityInfo;
     console.log("calendar activity:", activityInfo)
     // 更新日期活动 
     var dateSet = {}
     for (var i = 0; i < activityInfo.length; i++) {
       var s_time = activityInfo[i].totalInfo.beginTime
       var curdate = new Date(s_time)
       let nowYear = curdate.getFullYear(); // 获取年份
       let nowMonth = curdate.getMonth()+1; // 获取月份
       let day = curdate.getDate();
       day = day < 10 ? '0' + day : day;
       var dateKey = `${nowYear}/${nowMonth}/${day}`
       console.log('calendar date key: ', dateKey)
       if (dateKey in dateSet) {
         dateSet[dateKey] += 1
       } else {
         dateSet[dateKey] = 1
       }
     }
     var dateArr = this.state.dayList
     console.log("calendar  dateSet", dateSet, dateArr) 
     for (let i = 0; i < dateArr.length; i++) {
       if (dateArr[i].date in dateSet) {
         dateArr[i].actNum = dateSet[dateArr[i].date]
       }else{
        dateArr[i].actNum = 0
       }
     }
     this.setState({
        dayList: dateArr
      })
    }

    onChangeDate = (e)=>{
        console.log('Calendar.this onChangeDate', this);
        let day = e.target.dataset.day;
        let dateStr;
        this.state.dayList.map( dayObject => {
            if(dayObject.className === 'disable'){
                return;
            }
            if(dayObject.day+'' === day+''){
                dayObject.className = 'today'
            }else{
                dayObject.className = ''
            }
        })
        console.log("day", day);
        this.setState({
            dayList: this.state.dayList,
            currentDay: day
        },()=>{
            console.log("day1:", this.state.currentDay)
            dateStr = [this.state.currentYear, this.state.currentMonth, this.state.currentDay].join('/')+' 00:00:00';
            this.props.onChangeDay(dateStr)
        })
    }

    renderHeader() {
        return(
            <div className='calendar_title flex_nowrap'>
                <div className='icon' onClick = {this.preMonth}>
                    <img src={leftArrow} />
                </div>
                <div>{this.state.currentYear}年{this.state.currentMonth}月</div>
                    <div className='icon'  onClick = {this.nextMonth}>
                    <img src={rightArrow} />
                </div>
            </div>
        )
    }
    renderBody() {
        return(
            <div className = 'calendar-body'>
                <div className = 'week-container'>
                    {this.state.weekList.map((week, key) => {
                        return <div key = {key} className = {`week ${week.className}`}>{week.name}</div>
                    })}
                </div>
                <div className = 'day-container'>
                    {this.state.dayList.map( (dayObject, index) => {
                        let dateMarker, dayClassName = 'day '+ dayObject.className;
                        if(dayObject.actNum >= 1){
                            dateMarker = (<div className="date-marker flex-row flex-x-center flex-y-center">
                                <div className="circle-box">·</div>
                            </div>)
                        }else if(dayObject.actNum >= 2){
                            dateMarker = (<div className="date-marker flex-row flex-x-center flex-y-center">
                                <div className="circle-box">·</div>
                                <div className="circle-box">·</div>
                            </div>)
                        }else if(dayObject.actNum >= 3){
                            dateMarker = (<div className="date-marker flex-row flex-x-center flex-y-center">
                                <div className="circle-box">·</div>
                                <div className="circle-box">·</div>
                                <div className="circle-box">·</div>
                            </div>)
                        }
                        return (<div style={{ cursor: "pointer" }} onClick={dayObject.className === 'disable' ?null:this.onChangeDate} 
                        data-day={dayObject.day} key = {index} className = {dayClassName}>
                            {dayObject.day}
                            {dateMarker}
                        </div>)
                    })}
                </div>
            </div>
        )
    }

    render() {
        console.log('Calendar.this', this);
        return(
            <div className = 'calendar'>
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        )
    }
}

export default Calendar;