import React, { Component } from 'react';
import './headerUser.css'
import usrImg from '../../images/User.png';
import { Link } from 'react-router-dom';

class HeaderUser extends Component {
  constructor(props) {
    super(props);
    this.state={
      hoverUser: false
    }
  }
  
  onMouseEnterUser = ()=> {
    this.setState({
      hoverUser: true,
    });
  }
  onMouseLeaveUser = ()=>{
    this.setState({
      hoverUser: false,
    });   
  }
  render() {
      let userShow, userHoverBlock
      if(this.props.login){
        userShow = '欢迎您！'//this.props.userInfo.name
        userHoverBlock = (
          <div className="user-hover">
            <div className="user-name">{this.props.userInfo.name+'，欢迎您！'}</div>
            <div className="user-quit" onClick={this.props.logout}>退出登陆</div>
          </div>
        )
      }else{
        userShow = <img src={usrImg} alt="usr" />
        userHoverBlock = (
          <div className="user-hover">
            <Link to='/'>
              <div className="user-name">请先登录</div>
            </Link>
          </div>
        )
      }
      return (
          <div style={{ cursor: "pointer" }} onMouseEnter={this.onMouseEnterUser} onMouseLeave={this.onMouseLeaveUser}>
              {userShow}
              {this.state.hoverUser?userHoverBlock:''}
          </div>
      );
  }
}

export default HeaderUser;