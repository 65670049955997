/* jshint esversion: 6 */
import React, { Component } from 'react';
import './App.css';
import './global.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  Link
} from "react-router-dom";

import { connect } from 'react-redux';
import routes from './model/router.js';
import routesCopy from './model/router.js';
import routes2 from './model/router2.js';
import SearchBox from './components/searchBox/SearchBox';
import Footer from "./components/footer/Footer"
import tussarLogo from './images/banner-logo.jpg';
import HeaderUser from './components/header/HeaderUser'
import HeaderLoc from './components/header/HeaderLoc'

import {getSponsorList, getTopicList, getUserDetails, getAdsList, getCityList, getTypeList} from "./data"
import {_getActivityList} from "../src/utils/tools"

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hoverUser: false,
      hoverLoc: false
    };
  }
  componentDidMount() {
    this.props.getActivity();
    this.props.getSponsor();
    this.props.getTopic();
    this.props.setUserInfo();
    this.props.getAds();
    this.props.getCityList();
    this.props.getTypeList();
  }
  logout = ()=>{
    localStorage.removeItem("phone");
    this.props.setLoginStatus(false);
    this.props.getActivity();
  }
  render() {
    console.log("render:",this.props.sponsor,this.props.activity);
    let userLogin = false, locHoverBlock
    if(this.props.userInfo && localStorage.getItem("phone")){
      userLogin = true
    }
    
    return (
      <HashRouter>
      <div className="container-style">
        <nav className="nav-style header-wrapper flex-twoside flex-row flex-center">
          <div className="header  flex-twoside  flex-row flex-center">
          <img className="header-logo" alt="header-logo" src={tussarLogo}/>
            <ul className='header-ul flex-row '>
              {
                routes.map((route,key)=>{
                  return (
                    <li key={key}>
                      <Link to={route.path}>{route.name}</Link>
                    </li>
                  )
                })
              } 
              <li key="oldWeb">
                <a href="http://act.tusstar.com/" target="_blank" rel="noopener noreferrer">旧版入口</a>
              </li>
            </ul>
            <Link to='/TusstarWeb/search'><SearchBox /></Link>
            <div className="hearder-location">
              <HeaderLoc setActivityByLoc={this.props.setActivityByLoc}
              setActivityByLoc={this.props.setActivityByLoc}/>
            </div>
            <div className="usr-img">
              <HeaderUser history={this.props.history} login={userLogin} userInfo={this.props.userInfo} logout={this.logout}/>
            </div>
          </div>
        </nav>

        <div className="route-style">
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            {
                routesCopy.map((route,key)=>{
                  if(route.exact){
                    return <Route key={key} exact path={route.path} component={route.component}/>
                  }else{
                    return <Route key={key}  path={route.path} component={route.component}/>

                  }
                })
            }   
            {
              routes2.map((route,key)=>{
                return <Route key={key}  path={route.path} component={route.component}/>
              })
            } 
          </Switch>
        </div>

        <Footer />
      </div>
    </HashRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    loginStatus: state.loginStatus
  }
}
//需要触发什么行为
function mapDispatchToProps(dispatch) {
  return {
    getActivity: () => {
      _getActivityList().then(res=>{
        console.log("mapDispatchToProps getActivity:",res)
        dispatch({
          type:"get_activity",
          activity: res
        })
      })
    },
    setActivityByLoc: (locIndex) => {
      dispatch({
        type:"set_activity_byloc",
        locIndex: locIndex
      })
    },
    getSponsor: () => {
      getSponsorList().then(res=>{
        console.log("mapDispatchToProps getSponsor:",res.data.data)
        dispatch({
          type:"get_sponsor",
          sponsor: res.data.data
        })
      })
    },
    getTopic: () => {
      getTopicList().then(res=>{
        console.log("mapDispatchToProps getTopic:",res.data.data.list)
        dispatch({
          type:"get_topic",
          topic: res.data.data.list
        })
      })
    },
    setUserInfo: () => {
      if(!localStorage.getItem("phone")){
        dispatch({
          type:"set_userInfo",
          userInfo: null
        })
      }else{
        getUserDetails().then(res=>{
          dispatch({
            type:"set_userInfo",
            userInfo: res.data.data
          })
        })
      }
    },
    getAds: () => {
      getAdsList().then(res=>{
          console.log("mapDispatchToProps getAds:",res.data.data)
          dispatch({
              type:"get_ads",
              ads: res.data.data
          })
      })
    },
    setLoginStatus: (status) => {
        dispatch({
            type:"set_login_status",
            loginStatus: status
        })
    },
    getCityList: () => {
      getCityList().then(res=>{
          console.log("mapDispatchToProps getCityList:",res.data.data)
          dispatch({
              type:"get_city",
              city: res.data.data
          })
      })
    },
    getTypeList: () => {
      getTypeList().then(res=>{
        console.log("mapDispatchToProps getTypeyList:",res.data.data)
        dispatch({
            type:"get_type",
            typeList: res.data.data
        })
      })
    } 
  }
}

//连接组件
App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App;