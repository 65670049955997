import React, { Component } from 'react';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import SearchBox from '../../components/searchBox/SearchBox';
import ActivityItem from "../../components/activityItem/ActivityItem"
import { sortBySimilarity } from '../../utils/tools'
import './searchIndex.css';
const navTitle = '搜索';
const navList = ['活动', '主办方'];
class SearchIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activityInfoOri: this.props.activityInfoOri,
            sponsorInfo: this.props.sponsorInfo,
            topicInfo: this.props.topicInfo,
            searchRes: [],
            showType: 'activity',
            searchResAct: this.props.activityInfoOri,
            searchResSpon: this.props.sponsorInfo
        }
    }
    componentWillReceiveProps(nextProps) {
        const newActivity = nextProps.activityInfoOri
        const newSponsor = nextProps.sponsorInfo
        const newTopic = nextProps.topicInfo
        this.setState({
            activityInfoOri: newActivity,
            sponsorInfo: newSponsor,
            topicInfo: newTopic,
            searchResAct: newActivity,
            searchResSpon: newSponsor,
        })
    }
    onIptChange = (val) => {

        

        //let newArr = this.state.activityInfoOri.concat(this.state.sponsorInfo).concat(this.state.topicInfo)
        //let newActivity = this.state.activityInfoOri.sort(sortBySimilarity(["name","title"], val))
        //let newSponsor = this.state.sponsorInfo.sort(sortBySimilarity(["name","title"], val))
        let newActivity = val.length != 0 ? this.state.activityInfoOri.filter(x => x.title.indexOf(val) != -1) : this.state.activityInfoOri
        let newSponsor = val.length != 0 ? this.state.sponsorInfo.filter(x => x.name.indexOf(val) != -1) : this.state.sponsorInfo
        console.log("onIptChange:", newActivity);
        /*this.setState((prevState) =>{
            delete prevState.activityInfo;
            return prevState;
        })*/
        this.setState({
            searchResAct: [],//.slice(0,3),
            searchResSpon: [],//.slice(0,3)
        }, () => {
            //this.showLog();
        })

        setTimeout(() => {
            this.setState({
                searchResAct: newActivity,//.slice(0,3),
                searchResSpon: newSponsor,//.slice(0,3)
            }, () => {
                //this.showLog();
            })    
        }, 300);

        
    }

    showLog = () => {
        this.state.searchRes.map((val, key) => {
            console.log("title:", val.title)
        })
        console.log("=====================")
    }
    onNavClick = (idx) => {

        this.setState({
            navIdx: parseInt(idx),
        })
        console.log("onNavClick", idx);
        switch (parseInt(idx)) {
            case 0:
                this.setState({
                    showType: 'activity'
                });
                break;
            case 1:
                this.setState({
                    showType: 'sponsor'
                });
                break;
            default:
        }

    }
    likeSponsor = () => {
        console.log("likeSponsor click!")
    }

    render() {
        console.log("render======", this.state.activityInfo)
        let searchRes = this.state.showType === 'activity' ? this.state.searchResAct : this.state.searchResSpon

        // let activity = 
        console.log("this.state.showType:", this.state.showType, searchRes)
        return (
            <div className="search-wrapper page-padding page-min-height">
                <SubNavBar title={navTitle} navList={navList} navIdx="0"
                    onNavClick={this.onNavClick} />
                <SearchBox type="page" onChange={this.onIptChange} />
                <div>
                    {
                        searchRes.map((res, key) => {
                            // if(this.state.showType === "activity"){
                            //     return (
                            //         <ActivityItem key={res.name} data={res} idx={key} type='activity'/>
                            //     )
                            // }else{
                            //     return (
                            //         <ActivityItem key={res.title} data={res} idx={key} type='sponsor'/>
                            //     )
                            // }
                            // console.log(res)
                            return (
                                <p>{this.state.showType === 'activity' ? res.title : res.name}</p>,
                                <ActivityItem key={res.id} data={res} idx={key} type={this.state.showType}  />
                            )
                          
                        })

                    }
                </div>
            </div>
        );
    }
}

export default SearchIndex;