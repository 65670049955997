import React, { Component } from 'react';
import {login, getUserDetails} from "../../../data"
import { connect } from 'react-redux';
import './login.css'
import {_getActivityList} from "../../../../src/utils/tools"
import { Link } from 'react-router-dom';

class Login extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userName: "",
            userPassword: "",
            logining:false
        }
    }
    changeUsername(e){
        let uname = e.target.value;
        this.setState({
            userName: uname
        });
    }

    changePassword(e){
        let upwd = e.target.value;
        this.setState({
            userPassword: upwd
        })
    }
    handleClick(name, pwd){
        this.props.getActivity();
        if(!this.state.logining){
            login(name, pwd).then(res=>{
                if(res.data.message === "SUCCESS"){
                    localStorage.setItem("phone", name);
                    this.props.setUserInfo()
                    this.props.setLoginStatus(true)
                }else{
                    alert("账号或密码输入错误，请重新输入")
                }
            })
        }
    }
    render() {
        let path={
            pathname: "/TusstarWeb/register"
        }
        return (
            <div className="login-wrapper flex-column"> 
                <div className="flex-column">
                    <div className="title">启迪之星·活动</div>
                    <div className="title">T U S S T A R</div>
                </div>
                <div className="login-box">
                    <form action="" className="form-horizontal">
                        <div className="form-group input-text">
                            <input type="text" className="form-control" name="username" id="uname" ref="uname" placeholder="手机号/用户名"
                                onChange={this.changeUsername.bind(this)}/>
                        </div>
                        <div className="form-group input-text">
                            <input type="password" className="form-control" name="password" id="upwd" ref="upwd" placeholder="密码"
                                onChange={this.changePassword.bind(this)}/>
                        </div>
    ​
                        <div className="form-group">
                            <button type="button" onClick={this.handleClick.bind(this,this.state.userName,this.state.userPassword)} className="btn btn-primary login-button">登录</button>
                        </div>
                    </form>
                </div>  
                <Link  className="link-style-clear" to={path}>
                    <div className="register">没有账号？注册</div> 
                </Link>
            </div>
        );
    }
}
//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return { 
        getActivity: () => {
          _getActivityList().then(res=>{
            console.log("mapDispatchToProps getActivity:",res)
            dispatch({
              type:"get_activity",
              activity: res
            })
          })
        },
        setUserInfo: () => {
            getUserDetails().then(res=>{
                dispatch({
                type:"set_userInfo",
                userInfo: res.data.data
                })
            })
        },
        setLoginStatus: (status) => {
            dispatch({
                type:"set_login_status",
                loginStatus: status
            })
        }
    }
}
export default connect(null, mapDispatchToProps)(Login);