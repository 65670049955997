import React, { Component } from 'react';
import SearchIndex from './SearchIndex';
import { connect } from 'react-redux';
import {sortByEndTime} from '../../utils/tools'
class Search extends Component {
    render() {
        return (
            <div>
                <SearchIndex activityInfoOri={sortByEndTime(this.props.activity)}
                sponsorInfo={this.props.sponsor}
                topicInfo={this.props.topic}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        activity: state.activity,
        sponsor: state.sponsor,
        topic: state.topic
    }
}

export default connect(mapStateToProps, null)(Search);