import React, { Component } from 'react';
import SubNavBar from '../../components/subNavBar/SubNavBar';
import UserLike from "./userLike/UserLike";
import UserCard from "./userCard/UserCard";
import UserSignup from "./userSignup/UserSignup";
import UserSponsor from "./userSponsor/UserSponsor";
import UserScore from "./userScore/UserScore";
import {getUserDetails} from '../../data'

import { connect } from 'react-redux';
const navTitle ='我的';
const navList = ['名片档案','我的收藏','我的报名','关注的主办方','积分说明'];

let tempImage='https://matrixsci.oss-cn-beijing.aliyuncs.com/ned/citytips/citytips-banner-1-Ootmarsum.jpg';
class User extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            navIdx: sessionStorage.getItem("userNavIdx")?sessionStorage.getItem("userNavIdx"):0,
            userInfo: {
                name: 'Jennie',
                score: 666,
                level: '银粉',
                avatar: tempImage
            }
        }
    }
    componentWillMount() {
        console.log("user cwm");
        if(!localStorage.getItem("phone")){
            alert("请先登陆");
            this.props.history.push('/')
            return;
        }
    }
    componentDidMount() {
        this.props.getUser();
    }
    
    onNavChange = (idx)=> {
        sessionStorage.setItem("userNavIdx", idx);
        this.setState({
            navIdx:  parseInt(idx),
        })
    }

    render() {
        let userShow;

        console.log("this.props.userInfo", this.props.user);
        switch (parseInt(this.state.navIdx)){
            case 0:
                if(this.props.user){
                    userShow = <UserCard user = {this.props.user}/>
                }
                break;   
            case 1:
                userShow = <UserLike />
                break;   
            case 2:
                userShow = <UserSignup />
                break;
            case 3:
                userShow = <UserSponsor sponsorInfo={this.props.sponsor}/>
                break; 
            case 4:
                userShow = <UserScore />
                break;
            default:
                userShow ='';
        }
        return (
            <div className="flex-column page-min-height">
                <SubNavBar title={navTitle} navList={navList} navIdx={parseInt(this.state.navIdx)}
                onNavClick={this.onNavChange}/>
                <div className="full-width page-padding">
                    {userShow}
                </div>
            </div>
        );
    }
}
//需要渲染什么数据
function mapStateToProps(state) {
    return {
        sponsor: state.sponsor,
        user: state.user
    }
}
//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
      getUser: () => {
        getUserDetails().then(res=>{
          console.log("mapDispatchToProps getUser:",res.data.data)
          dispatch({
            type:"get_user",
            user: res.data.data
          })
        })
      }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(User);