import React, { Component } from 'react';
import { connect } from 'react-redux';
import RankIndex  from "./RankIndex"
import {notExpireAct} from '../../utils/tools'

class Rank extends Component {
    render() {
        return (
            <div className="page-padding">
                <RankIndex activityInfoOri={notExpireAct(this.props.activityByLoc)}/>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        activityByLoc: state.activityByLoc
    }
}
export default connect(mapStateToProps, null)(Rank);