import {
    getActivity,
    getCityData,
    getSponsorList
  } from '../data.js';
  
  export function timeTransfer(beginTime, endTime) {
    var time1 = new Date(beginTime)
    var time2 = new Date(endTime)
    var y1 = time1.getFullYear()
    var m1 = time1.getMonth() + 1
    var d1 = time1.getDate()
    var h1 = time1.getHours()
    var min1 = ('0'+time1.getMinutes()).slice(-2)
    var y2 = time2.getFullYear()
    var m2 = time2.getMonth() + 1
    var d2 = time2.getDate()
    var h2 = time2.getHours()
    var min2 = ('0'+time2.getMinutes()).slice(-2)
    if (y1 === y2 && m1 === m2 && d1 === d2) {
      return y1 + '/' + m1 + '/' + d1 + ' ' + h1 + ':' + min1 + ' - ' + h2 + ':' + min2
    } else {
      return y1 + '/' + m1 + '/' + d1 + ' ' + h1 + ':' + min1 + '\n - ' + y2 + '/' + m2 + '/' + d2 + ' ' + h2 + ':' + min2
    }
  }
  
  export function _getActivityList() {
    // 获取ActiviyList
    return getSponsorList().then(resSpon => {
      var sponmap = {}
      console.log('###: ', resSpon)
      var splist = resSpon.data.data
      for (var i = 0; i <splist.length;i++) {
        sponmap[splist[i].id] = splist[i].name
      }
      return getActivity().then(res => {
        var activityList = res.data.data
        var activityInfo = []
        for (var i = 0; i < activityList.length; i++) {
          var title = activityList[i].name
          var place = activityList[i].place
          var beginTime = activityList[i].beginTime
          var endTime = activityList[i].endTime
          var timeInfo = timeTransfer(beginTime, endTime)
          var status = activityList[i].attend
          // var status = 4
          var img = activityList[i].pictureLiveLink
          var price = activityList[i].price
          var sponsor = sponmap[activityList[i].sponsorId]
          var type = 'activity'
          var subtype = activityList[i].type
          var totalInfo = activityList[i]
          var data = {
            title: title,
            timeInfo: timeInfo,
            status: status,
            img: img,
            price: price,
            sponsor: sponsor,
            type: type,
            subtype: subtype,
            totalInfo: totalInfo
          }
          activityInfo.push(data)
        }
        console.log('tools.js: ', activityInfo)
        return activityInfo
      })
    })
  }

//字符串相似度比较
export function strSimilarity2Percent (s, t) {
  var n = s.length, m = t.length, d = [];
  var i, j, s_i, t_j, cost;
  if (n == 0) return m;
  if (m == 0) return n;
  for (i = 0; i <= n; i++) {
    d[i] = [];
    d[i][0] = i;
  }
  for (j = 0; j <= m; j++) {
    d[0][j] = j;
  }
  for (i = 1; i <= n; i++) {
    s_i = s.charAt(i - 1);
    for (j = 1; j <= m; j++) {
      t_j = t.charAt(j - 1);
      if (s_i == t_j) {
        cost = 0;
      } else {
        cost = 1;
      }
      d[i][j] = Minimum(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
    }
  }
  var l = s.length > t.length ? s.length : t.length;
  return (1 - d[n][m] / l).toFixed(4);
};

function Minimum (a, b, c) {
  return a < b ? (a < c ? a : c) : (b < c ? b : c);
};  

//（1）降序排列（2）参数：待比较对象属性，关键字
export function sortBySimilarity(property, keyword) {
  function sortfun(obj1, obj2) {
    let val1 = obj1[property[0]]?obj1[property[0]]:obj1[property[1]];
    let val2 = obj2[property[0]]?obj2[property[0]]:obj2[property[1]];
    let obj1Res = strSimilarity2Percent(val1, keyword);
    let obj2Res = strSimilarity2Percent(val2, keyword);
    if (obj1Res < obj2Res) return 1
    else if (obj1Res > obj2Res) return -1
    else if (obj1Res == obj2Res) return 0
  }
  return sortfun;
}

export function checkNotNull(val){
  return val ? true : false;
}
export function checkPhone(phoneNumber){
  let str = /^1\d{10}$/
  if (str.test(phoneNumber)) {
    return true;
  }
  return false;
}
export function checkEmail(email){
  let str = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
  if (str.test(email)) {
    return true;
  } 
  return false;
}
export function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.href.split("?")[1].match(reg);
  console.log("hi:", window.location.href.split("?")[1], r)
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

export function notExpireAct(activity){
    let nowDate = new Date().getTime()
    let filterActivity = [];
    if(activity){
        filterActivity = activity.filter(item=>item.totalInfo.canJoinTime >= nowDate)
    }
    return filterActivity
}


export function sortByEndTime (activity, status){
  if(!status){
    activity = activity.sort((a,b) => b.totalInfo.endTime - a.totalInfo.endTime)
  }else{
    activity = activity.sort((a,b) => a.totalInfo.endTime - b.totalInfo.endTime)
  }
  return activity
}
export function canSignUp(activity){
  let filterActivity = [];
  if(activity){
      filterActivity = activity.filter(item=>item.totalInfo.canJoin)
  }
  return filterActivity
}