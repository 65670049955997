import React, { Component } from 'react';
import { connect } from 'react-redux';
import avatarimg from '../../../images/avatar.png';
import './userCard.css'
import { putUserDetails, getCityList, getUserLevel, getInterestTagList } from "../../../data"
import { Upload, message, Select, Input, Checkbox, Message } from 'antd';
import 'antd/dist/antd.css';
var Option = Select.Option

const OSSUrl = "https://tusstaross.oss-cn-beijing.aliyuncs.com/"
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

class UserCard extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleInput = this.handleInput.bind(this);
        this.state = {
            OSSData: {},
            loading: false,
            user: this.props.user,
            userDesc: [
                {
                    name: '姓名',
                    attr: 'name',
                    value: '',
                    idx: 0
                }, {
                    name: '性别',
                    attr: 'sex',
                    value: '性别',
                    idx: 1
                }, {
                    name: '公司',
                    attr: 'company',
                    value: '',
                    idx: 2
                }, {
                    name: '职务',
                    attr: 'job',
                    value: '',
                    idx: 3
                }, {
                    name: '邮箱',
                    attr: 'email',
                    value: '',
                    idx: 4
                }, {
                    name: '手机号',
                    attr: 'phone',
                    value: '',
                    idx: 5
                }, {
                    name: '所在城市',
                    attr: 'city',
                    value: '所在城市',
                    idx: 6
                },
            ],
            checkbox: [
                {
                    idx: 0,
                    name: '投资人',
                    value: '',
                    checked: false
                }, {
                    idx: 1,
                    name: '创业者',
                    value: '',
                    checked: false
                }, {
                    idx: 2,
                    name: '企业管理者',
                    value: '',
                    checked: false
                }, {
                    idx: 3,
                    name: '企业员工',
                    value: '',
                    checked: false
                }, {
                    idx: 4,
                    name: '自由职业',
                    value: '',
                    checked: false
                }, {
                    idx: 5,
                    name: '其他',
                    value: '',
                    checked: false
                },
            ],
            checkboxIdx: [false, false, false],
            interestTagList: [],
            interestTagCheckList: []
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log('@@@@ UserCard: ', nextProps)
        this.setState({
            user: nextProps.user
        })
        this._updateData()
    }

    async componentDidMount() {
        this.setState({
            user: this.props.user
        })
        this._updateData()
        await this.init();
    }

    init = async () => {
        try {
            const OSSData = await this.mockGetOSSData();

            this.setState({
                OSSData,
            });
        } catch (error) {
            message.error(error);
        }
    };

    // Mock get OSS api
    // https://help.aliyun.com/document_detail/31988.html
    mockGetOSSData = () => {
        return {
            dir: 'tusstar/avatar/',
            expire: '1577811661',
            host: OSSUrl,
            accessId: 'LTAINcOTUV2N5lg3',
            policy: 'eyJleHBpcmF0aW9uIjoiMjAyMC0wMS0wMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
            signature: 'wttEoFs7yIl5o+tOb9OZr6B56v8=',
        };
    };

    handleInput = (e) => {
        e.persist()
        let idx = e.target.name
        let userDesc = this.state.userDesc
        userDesc[idx].value = e.target.value
        this.setState({
            userDesc: userDesc
        })
    }

    onChange = ({ fileList }) => {
        const { onChange } = this.props;
        var info = fileList[0]
        // 替换头像
        getBase64(info.originFileObj, imageUrl =>
            this.setState({
                imageUrl,
                loading: false,
            }),
        );
        // 更新用户信息
        let userInfo = this.state.user
        let userDesc = this.state.userDesc
        var avatarUrl = OSSUrl + info.originFileObj.url
        userInfo.avatarUrl = avatarUrl
        this.setState({
            user: userInfo
        })
        var name = userDesc[0].value !== '' ? userDesc[0].value : userInfo.name
        // var sex = userDesc[1].value !== '' ? userDesc[1].value : userInfo.sex
        var sex = 0
        var company = userDesc[2].value !== '' ? userDesc[2].value : userInfo.company
        var job = userDesc[3].value !== '' ? userDesc[3].value : userInfo.job
        var email = userDesc[4].value !== '' ? userDesc[4].value : userInfo.email
        var phone = userInfo.phone
        var city = userDesc[6].value !== '' ? userDesc[6].value : userInfo.city
        var passwd = userInfo.password
        console.log('#### onchange: ', avatarUrl, city, company, email, job, name, passwd, phone, sex)
        putUserDetails(avatarUrl, city, company, email, job, name, passwd, phone, sex).then(res => {
            if (res.data.code === 200) {
                console.log('上传信息成功 ########：')
                console.log(avatarUrl, city, company, email, job, name, passwd, phone, sex)
            } else {
                console.log('上传信息失败')
            }
        })
        // 这句话是抄的
        if (onChange) {
            onChange([...fileList]);
        }
    };

    beforeUpload = async () => {
        const { OSSData } = this.state;
        const expire = OSSData.expire * 1000;

        if (expire < Date.now()) {
            await this.init();
        }
        return true;
    };

    handleClick = (e) => {
        e.persist()
        let citymap = this.state.cityMap
        let userInfo = this.state.user
        let userDesc = this.state.userDesc
        var avatarUrl = userInfo.avatarUrl
        var name = userDesc[0].value !== '' ? userDesc[0].value : userInfo.name
        var sex = userDesc[1].value === '男' ? 1 : 2
        var company = userDesc[2].value !== '' ? userDesc[2].value : userInfo.company
        var job = userDesc[3].value !== '' ? userDesc[3].value : userInfo.job
        var email = userDesc[4].value !== '' ? userDesc[4].value : userInfo.email
        var phone = userInfo.phone
        var city = citymap[userDesc[6].value]
        var passwd = userInfo.password
        var tag = this.state.checkbox.filter(x => x.checked).map(x => x.idx + 1)
        if (tag.length == 0) {
            message.error('请选择标签')
            return;
        }
        var interestTag = this.state.interestTagCheckList.filter(x => x.checked).map(x => x.name)
        if (this.state.interestTagList.length != 0 && interestTag.length == 0) {
            message.error('请选择兴趣标签')
            return;
        }
        console.log('######fsdfsdfsd: ', avatarUrl, tag)
        putUserDetails(avatarUrl, city, company, email, job, name, passwd, phone, sex, tag, interestTag).then(res => {
            if (res.data.code === 200) {
                console.log(avatarUrl, city, company, email, job, name, passwd, phone, sex, tag, interestTag)
                message.success('上传信息成功')
            } else {
                console.log('上传信息失败')
                message.error('上传信息失败')
            }
        })
    }

    handleSelectSex = (value) => {
        let userDesc = this.state.userDesc
        console.log('@@@@ selected ', value, userDesc);
        userDesc[1]['value'] = value
        this.setState({
            userDesc: userDesc
        })
    }

    handleSelectCity = (value) => {
        var userDesc = this.state.userDesc
        var citymap = this.state.cityMap
        userDesc[6]['value'] = value
        this.setState({
            userDesc: userDesc
        })
    }

    handleChange = (e) => {
        let options = e.target.options
        let idx = options.idx
        let checkbox = this.state.checkbox
        checkbox[idx].checked = e.target.checked
        this.setState({
            checkbox: checkbox
        })
    }
    handleChangeForInterestTag = (e) => {
        let options = e.target.options
        let idx = options.idx
        let interestTagCheckList = this.state.interestTagCheckList
        interestTagCheckList[idx].checked = e.target.checked
        this.setState({
            interestTagCheckList: interestTagCheckList
        })
    }

    _updateData = () => {
        getUserLevel().then(res => {
            this.setState({
                userLevel: res.data.data
            })
        })
        getInterestTagList().then(res => {
            let interestTagList = res.data.data;
            let interestTagCheckList = [];
            let interestTagDataList = this.state.user.interestTagDataList
            if (interestTagDataList) {
                interestTagList.forEach((item, index) => {
                    interestTagCheckList.push({
                        idx: index,
                        name: item.name,
                        value: '',
                        checked: interestTagDataList.some(function (tag) {
                            return tag === item.name;
                        })
                    })
                })
            }

            this.setState({
                interestTagList: interestTagList,
                interestTagCheckList: interestTagCheckList
            })
        })

        getCityList().then(res => {
            var cityInfo = res.data
            var cityList = []
            var cityMap = {}
            var cityOption = []
            for (var i = 0; i < cityInfo.length; i++) {
                var curcity = cityInfo[i].Name
                cityList.push(curcity)
                cityMap[curcity] = i
                if (i === 0) continue
                cityOption.push(<Option key={curcity}>{curcity}</Option>)
            }
            cityList[0] = '所在城市'
            this.setState({
                cityList: cityList,
                cityMap: cityMap,
                cityOption: cityOption
            })
            let userInfo = this.state.user
            let userDesc = this.state.userDesc
            let checkbox = this.state.checkbox
            userDesc[0].value = userInfo.name
            userDesc[1].value = userInfo.sex === 1 ? '男' : '女'
            userDesc[2].value = userInfo.company
            userDesc[3].value = userInfo.job
            userDesc[4].value = userInfo.email
            userDesc[5].value = userInfo.phone
            userDesc[6].value = cityList[userInfo.city]
            let tag = userInfo.tag
            if (tag) {
                let tag_arr = JSON.parse(tag)
                for (i = 0; i < tag_arr.length; i++) {
                    checkbox[parseInt(tag_arr[i]) - 1].checked = true
                }
            }
            this.setState({
                userDesc: userDesc,
                checkbox: checkbox
            })
        })
    }

    transformFile = file => {
        const { OSSData } = this.state;

        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        file.url = OSSData.dir + filename;

        return file;
    };

    getExtraData = file => {
        const { OSSData } = this.state;
        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessId,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };

    render() {
        let userInfo = this.state.user;
        let userDesc = this.state.userDesc
        let cityOption = this.state.cityOption
        let level = this.state.userLevel
        console.log('render(): ', userDesc)
        console.log('头像: ', userInfo.avatarUrl)
        // 上传头像
        const uploadButton = (
            <img src={(typeof (userInfo.avatarUrl) === 'string' && userInfo.avatarUrl.indexOf('avatar.png') === -1) ? userInfo.avatarUrl : avatarimg} alt="avatar" className="avatar" />
        );
        const { value } = this.props;
        const props = {
            name: 'file',
            fileList: value,
            action: this.state.OSSData.host,
            onChange: this.onChange,
            // onRemove: this.onRemove,
            className: "avatar",
            transformFile: this.transformFile,
            data: this.getExtraData,
            beforeUpload: this.beforeUpload,
        };
        const { imageUrl } = this.state;
        const interestTag  = this.state.interestTagList.length != 0;
        return (
            <div className="full-width">
                <div className="card-header flex-row flex-y-center">
                    <Upload {...props}>
                        {imageUrl ? <img src={imageUrl} alt="avatar" className="avatar-img" /> : uploadButton}
                    </Upload>
                    <div className="userName">{userInfo.name}</div>
                    <div className="score">{userInfo.score}</div>
                    <div className="level">{level}</div>
                </div>

                <div className="card-title">我的名片</div>
                <div className="card-desc-box-1 flex-row flex-wrap">
                    {
                        userDesc.map((item, key) => {
                            if (item.name === '性别') {
                                return <div key={key} className="card-desc-userCard" >
                                    <Select defaultValue="性别" value={item.value} showSearch={true} size="large" style={{ width: "100%" }}
                                        searchPlaceholder="性别"
                                        onChange={this.handleSelectSex}>
                                        <Option value="男">男</Option>
                                        <Option value="女">女</Option>
                                    </Select>
                                </div>
                            } else if (item.name === '所在城市') {
                                return <div key={key} className="card-desc-userCard" >
                                    <Select defaultValue="所在城市" value={item.value} showSearch={true} size="large" style={{ width: "100%" }}
                                        searchPlaceholder="所在城市"
                                        onChange={this.handleSelectCity}>
                                        {cityOption}
                                    </Select>
                                </div>
                            }
                            else {
                                return <div key={key} className="card-desc-userCard" >
                                    <Input placeholder={item.name} size="large" type="text" name={item.idx} value={item.value || ''} onChange={this.handleInput}></Input>
                                </div>
                            }
                        })
                    }
                </div>

                <div className="card-subtitle">我的标签</div>

                <div className="card-checkbox flex-row flex-twoside">
                    {
                        this.state.checkbox.map((item, key) => {
                            return (
                                <div>
                                    <Checkbox options={item} checked={item.checked} onChange={this.handleChange}>{item.name}</Checkbox>
                                    <br />
                                </div>
                            )
                        })
                    }
                </div>


                {interestTag>0 ? <div className="card-subtitle">兴趣标签</div> :""}

                <div className="card-checkbox flex-row flex-twoside">
                    {
                        this.state.interestTagCheckList.map((item, key) => {
                            return (
                                <div>
                                    <Checkbox options={item} checked={item.checked} onChange={this.handleChangeForInterestTag}>{item.name}</Checkbox>
                                    <br />
                                </div>
                            )
                        })
                    }
                </div>




                <button className="card-btn flex-row flex-x-center flex-y-center" onClick={this.handleClick}>保存修改</button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sponsor: state.sponsor,
        activity: state.activity,
        user: state.user
    }
}


export default connect(mapStateToProps, null)(UserCard);