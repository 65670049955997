import React, { Component } from 'react';
import Text from "../Text"
import './footer.css'

class Footer extends Component {
    render() {
        return (
            <div className="footer-wrapper">
                {/* <Text>京ICP备13021136号-3 京公网安备11010802010945号 </Text> */}
                {<a href='https://www.miit.gov.cn/' target='_blank'>京ICP备13021136号-3 京公网安备11010802010945号 </a>}
            </div>
        );
    }
}

export default Footer;