import axios from 'axios';

//const BASE_URL = '/tusstartest/';
const BASE_URL = '/tusstar/';
const OSS_BASE_URL = 'https://tusstaross.oss-cn-beijing.aliyuncs.com/'



export function getCityData() {
    return localStorage.get("currentCity").subStr(0, 2);
}
export function getSponsorList() {
    var phone = localStorage.getItem('phone')
    return _get('sponsor/list?phone=' + phone)
}
export async function getSponsorListSync() {
    let url = 'sponsor/list';
    await axios({
        method: "get",
        url: `${BASE_URL}${url}`
    }).then(res => {
        console.log("getSponsorListSync:", res.data.data);
        return res.data.data
    })
}
export function getTopicList() {
    return _get('topic/list')
}
export function getActivity() {
    var phone = localStorage.getItem('phone');
    if (typeof(phone) != 'undefined') {
        return _post('activity/query?phone=' + phone)
    } else {
        return _post('activity/query')
    }
}
export function getUserDetails() {
    var phone = localStorage.getItem('phone');
    return _get('user?phone=' + phone)
}
export function login(phone, passwd) {
    var data = {
        password: passwd,
        phone: phone
    }
    return _post('user/login', data)
}
export function register(phone, passwd, interestTag) {
    var data = {
        password: passwd,
        phone: phone,
        interestTag: interestTag
    }
    return _post('user/register', data)
}
export function getAdsList() {
    return _get('ads')
}
export function getScore() {
    var phone = localStorage.getItem('phone');
    var recent = true
    return _get('scoreconfig/logs?phone=' + phone + '&recent=' + recent)
}
export function likeSponsor(id) {
    var phone = localStorage.getItem('phone');
    var data = {
        phone: phone,
        sponsorId: id
    }
    console.log("likesponsor data", data);
    return _post('sponsor/like', data)
}
export function unLikeSponsor(id) {
    var phone = localStorage.getItem('phone');
    var data = {
        phone: phone,
        sponsorId: id
    }
    return _delete('sponsor/like', data)
}
export function likeAct(actId) {
    var phone = localStorage.getItem('phone');
    var data = {
        activityId: actId,
        phone: phone
    }
    return _post('activity/like', data)
}
export function unLikeAct(actId) {
    var phone = localStorage.getItem('phone');
    var data = {
        activityId: actId,
        phone: phone
    }
    return _delete('activity/like', data)
}
export function putUserDetails(avatarUrl, city, company, email, job, name, passwd, phone, sex, tag, interestTag) {
    var data = {
        avatarUrl: avatarUrl,
        city: city,
        company: company,
        email: email,
        job: job,
        name: name,
        password: passwd,
        phone: phone,
        sex: sex,
        tag: tag,
        interestTag: interestTag
    }
    console.log(data)
    return _put('user', data)
}
export function applyActivity(phone, actId, company, companyStatus, email, job, name, contact, bpLink) {
    var data = {
        activityId: actId,
        company: company,
        companyStatus: companyStatus,
        contact: contact,
        email: email,
        job: job,
        name: name,
        phone: phone,
        bpLink: bpLink
    }
    console.log('applyActivity: ', data)
    return _post('activity/attend', data)
}
export function unapplyActivity(phone, actId) {
    var data = {
        activityId: actId,
        phone: phone
    }
    return _delete('activity/attend', data)
}
export function viewAct(id) {
    var data = {
        id: id
    }
    return _post('activity/view', data)
}
export function getCityList() {
    return _get_oss('tusstar/city.json')
}
export function getUserLevel() {
    var phone = localStorage.getItem('phone');
    return _get('user/level?phone=' + phone)
}

export function getInterestTagList() {
    return _get('interest/tag/list')
}

export function getTypeList() {
    return _get('activity/type')
}
export function sendVeriCode(phone) {
    return _get('user/verification?phone=' + phone)
}

// 请求前拦截
axios.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        console.log("请求超时");
        return Promise.reject(err);
    }
);

// 返回后拦截
axios.interceptors.response.use(
    data => {
        return data;
    },
    err => {
        if (err.response.status === 504 || err.response.status === 404) {
            console.log("服务器被吃了⊙﹏⊙∥");
        } else if (err.response.status === 401) {
            console.log("登录信息失效⊙﹏⊙∥");
        } else if (err.response.status === 500) {
            console.log("服务器开小差了⊙﹏⊙∥");
        }
        return Promise.reject(err);
    }
);


// @_post请求
const _post = (url, params) => {
    return axios({
        method: "post",
        url: `${BASE_URL}${url}`,
        data: params,
        headers: {
            "Content-Type": "application/json",
            charset: "utf-8"
        }
    });
};
// @_get请求
const _get = url => {
    return axios({
        method: "get",
        url: `${BASE_URL}${url}`
    });
};
// @_get请求
const _get_oss = url => {
    return axios({
        method: "get",
        url: `${OSS_BASE_URL}${url}`
    });
};
// @_put请求
const _put = (url, params) => {
    return axios({
        method: "put",
        url: `${BASE_URL}${url}`,
        data: params,
        headers: {
            "Content-Type": "application/json",
            charset: "utf-8"
        }
    });
};
// @_delete请求
const _delete = (url, params) => {
    return axios({
        method: "delete",
        url: `${BASE_URL}${url}`,
        data: params,
        headers: {
            "Content-Type": "application/json",
            charset: "utf-8"
        }
    });
};
// 并发多个请求
const multiple = function(requsetArray, callback) {
    axios.all(requsetArray).then(axios.spread(callback));
};