import React, { Component } from 'react';
import './select.css'

class Select extends Component {
    handleChange = (event)=>{
        this.props.onChange(parseInt(event.target.value), this.props.idx);
    }
    render() {
        return (
            <select value={this.props.value} onChange={this.handleChange}>
                {
                    this.props.options.map((option, key)=>{
                        return (
                            <option key={key} value={option.value}>{option.label}</option>
                        )
                    })
                }
            </select>
        );
    }
}

export default Select;