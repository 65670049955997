const locaList = [
    "全国",
    "北京",
    "上海",
    "天津",
    "重庆",
    "河北",
    "山西",
    "吉林",
    "辽宁",
    "黑龙江",
    "陕西",
    "甘肃",
    "青海",
    "山东",
    "福建",
    "浙江",
    "河南",
    "湖北",
    "湖南",
    "江西",
    "江苏",
    "安徽",
    "广东",
    "海南",
    "四川",
    "贵州",
    "云南",
    "内蒙古",
    "新疆",
    "宁夏",
    "广西",
    "西藏",
    "港澳台及海外",
]

export default locaList;
 
