import React, { Component } from 'react';
import { connect } from 'react-redux';
import {checkNotNull, checkPhone, checkEmail} from '../../utils/tools'
import {applyActivity} from '../../data'
import './signup.css'
import { Upload, Button, Icon, message } from 'antd';
import 'antd/dist/antd.css';
const OSSUrl = "https://tusstaross.oss-cn-beijing.aliyuncs.com/"
class Signup extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            actId: this.props.location.state,
            OSSData: {},
            signupDesc:[
                {
                    name:'姓名',
                    attr:'name',
                    value:'',
                    idx: 0,
                    alertStr:'*姓名不为空'
                },{
                    name:'手机号',
                    attr:'phone',
                    value:'',
                    idx: 1,
                    alertStr:'*请填写正确的手机号'
                },{
                    name:'公司',
                    attr:'company',
                    value:'',
                    idx: 2,
                    alertStr:'*公司不为空'
                },{
                    name:'邮  箱',
                    attr:'email',
                    value:'',
                    idx: 3,
                    alertStr:'*请填写正确的邮箱'
                }
            ],
            radioList:[
                {
                    name:'启迪之星在孵企业',
                    value:''
                },{
                    name:'启迪之星毕业企业',
                    value:''
                },{
                    name:'非启迪之星入驻企业',
                    value:''
                }
            ],
            radioValue: -1,
            formAlert: [false, false, false, false, false],
            filename: '未上传'
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            user: nextProps.user
        })
    }

    componentDidMount() {
        let OSSData = this.mockGetOSSData();
        this.setState({OSSData: OSSData})
    }
    handleChange = (e) =>{
        let item = parseInt(e.currentTarget.dataset.key);
        this.setState({
            radioValue: item,
            formAlert: [false, false, false, false, false]
        });
    }

    handleInput = (e) => {
        e.persist()
        let idx = e.target.name
        let signupDesc = this.state.signupDesc
        signupDesc[idx].value = e.target.value
        this.setState({
            signupDesc: signupDesc,
            formAlert: [false, false, false, false, false]
        })
    }

    transformFile = file => {
        const { OSSData } = this.state;
    console.log("file name:", file.name)
        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = Date.now() + suffix;
        file.url = OSSData.dir + filename;
    
        return file;
    };
    getExtraData = file => {
        const { OSSData } = this.state;
        console.log("file extra:", file.name)
        return {
            key: file.name,
            OSSAccessKeyId: OSSData.accessId,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };

    handleClick = () =>{
        if(!localStorage.getItem("phone")){
            this.props.history.push('/TusstarWeb')
            return;
        }
        let formAlert = [false, false, false, false];
        let signupDesc = this.state.signupDesc;
        let radioValue = this.state.radioValue;
        formAlert[0] = !checkNotNull(signupDesc[0].value);
        formAlert[1] = !checkPhone(signupDesc[1].value);
        formAlert[2] = !checkNotNull(signupDesc[2].value);
        formAlert[3] = !checkEmail(signupDesc[3].value);
        formAlert[4] = radioValue === -1
        console.log("请填写完整:", formAlert, formAlert.toString())
        if(formAlert.toString()!=="false,false,false,false,false"){
            this.setState({formAlert: formAlert})
            return
        }else{
          console.log("可以提交");
          let signupDesc = this.state.signupDesc;
          var phone = localStorage.getItem("phone")
          var actId = this.state.actId
          var name = signupDesc[0].value
          var contact = signupDesc[1].value
          var company = signupDesc[2].value
          var email = signupDesc[3].value
          var job = ''
          var companyStatus = this.state.radioList[this.state.radioValue].name
          var fileUrl = this.state.fileUrl
          console.log("back:", phone, actId, company, companyStatus, email, job, name, contact)
          
          applyActivity(phone, actId, company, companyStatus, email, job, name, contact, fileUrl).then(res => {
            console.log('提交结果: ', res)
            if(res.data.code === 200){
                alert("报名成功!")
                //更新信息
                this.props.changeAttendStatus(actId,1)
                //返回上一页
                this.props.history.push({ 
                    pathname: `/TusstarWeb/activity-detail/?id=${actId}`,
                    state: actId
                }) 
                return;
            }else{
                alert(res.message)
            }
          })
          return;
        }
    }
    // Mock get OSS api
    // https://help.aliyun.com/document_detail/31988.html
    mockGetOSSData = () => {
        return {
            dir: 'tusstar/bp/',
            expire: '1577811661',
            host: OSSUrl,
            accessId: 'LTAINcOTUV2N5lg3',
            policy: 'eyJleHBpcmF0aW9uIjoiMjAyMC0wMS0wMVQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
            signature: 'wttEoFs7yIl5o+tOb9OZr6B56v8=',
        };
    };
    getfileURL = () =>{
        const _this = this;
        const props = {
            name: 'file',
            action: this.state.OSSData.host,//AjaxUrl + 'bp/' + this.state.actId +'/' + localStorage.getItem("phone"),/*文件上传接口和需要的传参*/
            accept:"application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",/*接受上传的文件类型：此处为.doc格式*/
            headers: {
                authorization: 'authorization-text',
            },
            transformFile: this.transformFile,
            data: this.getExtraData,
            onChange(info) {//上传文件改变时的状态
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    console.log(info.file, info.fileList);
                    message.success(`${info.file.name} 上传成功！`);
                    console.log("上传成功！",info.file);
                    _this.setState({
                        filename: info.file.name,
                        fileUrl:OSSUrl + info.file.name,
                        wordName:info.file.name
                    })
 
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败！`);
                }
            },
        };
        return props;
    }

    render() {
 
        return (
            <div className="signup-box page-padding">

                <div className="card-title">报名表单</div>
                <div className="card-desc-box flex-row flex-wrap">
                    {
                        this.state.signupDesc.map((item, key)=>{
                            return (<div key={key} className="card-desc" >
                                    <div>{item.name}: 
                                    <input type="text" name={item.idx} value={item.value||''} onChange={this.handleInput} />
                                    </div>
                                    <div className="form-alert" hidden={!this.state.formAlert[key]}>{item.alertStr}</div>
                                </div>)
                        })
                    }
                </div>

                <div className="flex-row">
                    <div className="card-subtitle">公司状况</div>
                    <div className="form-alert" hidden={!this.state.formAlert[4]}>*请勾选公司状况</div>
                </div>

                <div className="card-radiobox flex-row flex-twoside">
                    {
                        this.state.radioList.map((item, key)=>{
                            return (
                                <div key={key} className="radiobox-wrapper flex-row flex-y-center" data-key={key}
                                onClick={this.handleChange} >
                                    <div className="selected">{this.state.radioValue===key ? '√': ''}</div>
                                    <div className="radiobox-name">{item.name}</div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="flex-row">
                    <div>上传BP</div>
                    <Upload {...this.getfileURL()} showUploadList={false}>
                        <Button>
                        <Icon type="upload" /> 上传文件
                        </Button>
                    </Upload>
                    <span>{this.state.filename}</span>
                </div>

                <button className="card-btn flex-row flex-x-center flex-y-center" onClick={this.handleClick}>报 名</button>
               </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        sponsor: state.sponsor,
        activity: state.activity,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        changeAttendStatus: (actId, status) => {
          dispatch({
            type:"change_attend_status",
            data: {
                actId: actId,
                status: status
            }
          })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);